import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

import { LookupListService, TenantService } from '@core/services';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'oiq-edit-lookup-list-dialog',
  templateUrl: './edit-lookup-list-dialog.component.html',
  styleUrls: ['./edit-lookup-list-dialog.component.scss']
})
export class EditLookupListDialogComponent implements OnInit, OnDestroy {

  dialogTitle: string = 'Value';

  form: FormGroup;

  private destroy$ = new Subject<void>();

  constructor(
    private fb: FormBuilder,
    private lookupListService: LookupListService,
    private tenantService: TenantService,
    public dialogRef: MatDialogRef<EditLookupListDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) { }

  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
  }

  ngOnInit() {
    const valueValidators = [Validators.required];
    if (this.data.type === 'Application Types') {
      valueValidators.push(Validators.pattern(/^[^`~!@#$%\^&*()+={}|[\]\\:';"<>?,./]*$/));
      this.dialogTitle = 'Application Type';
    }
    this.form = this.fb.group({
      id: [],
      type: ['', Validators.required],
      value: ['', Validators.compose([...valueValidators])],
      tenantId: ['']
    });

    if (this.data.lookupList) {
      this.form.patchValue(this.data.lookupList);
    }

    this.form.patchValue({
      type: this.data.type,
      tenantId: this.data.tenantId
    });
  }

  save() {
    let observable;

    if (this.data.type !== 'Application Types') {
      if (this.data.lookupList) {
        observable = this.lookupListService.update(this.form.value.id, this.form.value);
      } else {
        observable = this.lookupListService.create(this.form.value);
      }
    } else {
      const newValue = this.form.value.value.trim();
      if (this.data.lookupList) {
        observable = this.tenantService.editApplicationType(this.data.tenantId, this.data.lookupList.value, newValue)
      } else {
        observable = this.tenantService.saveApplicationType(this.data.tenantId, newValue)
      }
    }

    observable
      .pipe(takeUntil(this.destroy$))
      .subscribe((r: any) => {
        this.dialogRef.close(true);
      });
  }

  close(): void {
    this.dialogRef.close();
  }

}
