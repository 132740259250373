import { FormControl, FormGroup, FormArray, Validators, AbstractControl } from '@angular/forms';
import { Address } from './address';
import { Iso } from './iso';
import { Contact } from './contact';
import { Offer } from './offer';

export class ApplicationUpdate extends FormGroup {
  constructor() {
    super({
      whiteLabelId: new FormControl(),
      type: new FormControl(),
      installment: new FormControl()
    });
  }
}

export class CreditCheckRequest extends FormGroup {
  constructor(contactId?: string, firstName?: string, lastName?: string, ssn?: string, address1?: string, address2?: string, city?: string, state?: string, zipCode?: string, dob?: string, exclude?: boolean) {
    super({
      contactId: new FormControl(contactId, Validators.required),
      firstName: new FormControl(firstName, Validators.required),
      lastName: new FormControl(lastName, Validators.required),
      ssn: new FormControl(ssn, Validators.required),
      address1: new FormControl(address1, Validators.required),
      address2: new FormControl(address2),
      city: new FormControl(city, Validators.required),
      state: new FormControl(state, Validators.required),
      zipCode: new FormControl(zipCode, Validators.required),
      dob: new FormControl(dob, Validators.required),
      exclude: new FormControl(exclude)
    });
  }
}

export class RunCreditCheckRequest extends FormGroup {
  constructor() {
    super({
      contactId: new FormControl(),
      addressId: new FormControl(),
      firstName: new FormControl('', Validators.required),
      lastName: new FormControl('', Validators.required),
      address: new Address(),
      addresses: new FormArray([new Address()]),
      ssn: new FormControl('', Validators.required),
      dob: new FormControl()
    });
  }
}

export class DecisionLogicRequest extends FormGroup {
  constructor() {
    super({
      bankId: new FormControl('', Validators.required),
      accountNumber: new FormControl('', Validators.required),
      routingNumber: new FormControl('', Validators.required),
      contactId: new FormControl(''),
      emailAddress: new FormControl('', { validators: Validators.email, updateOn: 'blur' }),
      notificationTarget: new FormControl('Client', Validators.required)
    });
  }
}

export class PlaidRequest extends FormGroup {
  constructor() {
    super({
      contactId: new FormControl('', Validators.required),
      applicationId: new FormControl(''),
      notificationTarget: new FormControl('Client', Validators.required),
      emailAddress: new FormControl('', { validators: Validators.email, updateOn: 'blur' }),
    });
  }
}

export class Stipulation extends FormGroup {
  constructor() {
    super({
      id: new FormControl(),
      name: new FormControl(),
      tenantId: new FormControl()
    });
  }
}

export class ApplicationStipulation extends FormGroup {
  constructor() {
    super({
      id: new FormControl(),
      name: new FormControl(),
      status: new FormControl(),
      notes: new FormControl(),
      tenantId: new FormControl()
    });
  }
}

export class ApplicationApproval extends FormGroup {
  constructor(id, applicationId) {
    super({
      id: new FormControl(id),
      applicationId: new FormControl(applicationId),
      fundingMethod: new FormControl('ACH', Validators.required),
      fundingProcessor: new FormControl('', Validators.required),
      paymentMethod: new FormControl('ACH', Validators.required),
      paymentProcessor: new FormControl('', Validators.required),
      fundingBankId: new FormControl(),
      paymentBankId: new FormControl()
    });
  }
}

export class RefinancedFunding extends FormGroup {
  constructor() {
    super({
      id: new FormControl(),
      fundingId: new FormControl(),
      applicationId: new FormControl(),
      source: new FormControl(),
      companyName: new FormControl('', Validators.required),
      payoffAmount: new FormControl('', Validators.required),
      transmissionMethod: new FormControl('', Validators.required),
      transmissionProcessor: new FormControl(),
      routingNumber: new FormControl(),
      accountNumber: new FormControl(),
      bankId: new FormControl(),
      deleted: new FormControl(false)
    });
  }
}

export class Credit extends FormGroup {
  constructor() {
    super({
      id: new FormControl(''),
      type: new FormControl(''),
      amount: new FormControl(0, Validators.required),
      bankId: new FormControl('', Validators.required),
      creditDate: new FormControl('', Validators.required),
      transmissionMethod: new FormControl('', Validators.required),
      transmissionProcessor: new FormControl('', Validators.required)
    });
  }
}

export class ApplicationSettings extends FormGroup {
  constructor() {
    super({
      notificationSettings: new NotificationSettings()
    });
  }
}

export class NotificationSettings extends FormGroup {
  constructor() {
    super({
      overrideDefaultSettings: new FormControl(false),
      events: new FormArray([]),
      sendIsoNonFundingEmailNotifications: new FormControl(),
      sendIsoNonFundingSmsNotifications: new FormControl(),
      sendIsoFundingEmailNotifications: new FormControl(),
      sendIsoFundingSmsNotifications: new FormControl(),
      sendSyndicatorEmailNotifications: new FormControl(),
      sendSyndicatorSmsNotifications: new FormControl(),
      sendEmailNotifications: new FormControl(),
      sendSmsNotifications: new FormControl(),
      failedPaymentNotificationTarget: new FormControl()
    });
  }
}

export class Email extends FormGroup {
  constructor() {
    super({
      email: new FormControl('', [Validators.required, Validators.email])
    });
  }
}

export class ApplicationListExport extends FormGroup {
  constructor() {
    super({
      output: new FormControl('Excel', Validators.required),
      type: new FormControl('Standard', Validators.required),
      tenantUserIdRecipients: new FormControl(),
      isoUserIdRecipients: new FormControl(),
      isoEmailAddressRecipients: new FormControl(),
      emailAddressRecipients: new FormArray([]),
      emailMessage: new FormControl(),
      includeType: new FormControl(true, Validators.required),
      includeStatus: new FormControl(true, Validators.required),
      includeClientName: new FormControl(true, Validators.required),
      includeClientLegalName: new FormControl(true, Validators.required),
      includeDateSubmitted: new FormControl(true, Validators.required),
      includeDateApproved: new FormControl(false, Validators.required),
      includeApprovedAmount: new FormControl(false, Validators.required),
      includeDateFunded: new FormControl(false, Validators.required),
      includeFundingStatus: new FormControl(false, Validators.required),
      includePaymentFrequency: new FormControl(false, Validators.required),
      includeAchAmount: new FormControl(false, Validators.required),
      includePercentagePaidIn: new FormControl(false, Validators.required),
      includeBuyRate: new FormControl(false, Validators.required),
      includeFactorRate: new FormControl(false, Validators.required),
      includePosition: new FormControl(false, Validators.required),
      includePayBackAmount: new FormControl(false, Validators.required),
      includeBalanceWithFees: new FormControl(false, Validators.required),
      includeDateDeclined: new FormControl(false, Validators.required),
      includeDeclineReason: new FormControl(false, Validators.required),
      includeUnderwriterNotes: new FormControl(false, Validators.required),
      includeCreatedDate: new FormControl(false, Validators.required),
      includeStatusChangeDate: new FormControl(false, Validators.required)
    });
  }
}

export class RefinanceForm extends FormGroup {
  constructor() {
    super({
      externalFundings: new FormArray([]),
      refinanceBalanceSource: new FormControl({ value: 'Outstanding_Balance', disabled: true }),
      refinanceBalanceSourceIncludeFees: new FormControl({ value: true, disabled: true }),
      outstandingBalance: new FormControl(0),
      outstandingBalanceWithFees: new FormControl(0),
      pendingBalance: new FormControl(0),
      pendingBalanceWithFees: new FormControl(0),
      externalBalance: new FormControl(0),
      totalPayoffBalance: new FormControl(0),
      totalPayoffBalanceWithFees: new FormControl(0),
      refinanceAmount: new FormControl(0),
      disbursementAmount: new FormControl(0),
      refinanceNotes: new FormControl(),
    });
  }
}

export class FundingOnlyApplication extends FormGroup {
  constructor() {
    super({
      client: new FormGroup({
        id: new FormControl(),
        legalName: new FormControl(),
        dbaName: new FormControl(),
        email: new FormControl('', { validators: Validators.email, updateOn: 'blur' }),
        phoneNumber: new FormControl(),
        cellNumber: new FormControl(),
        grossMonthlySales: new FormControl(),
        // industryType: new FormControl(),
        // sicCode: new FormControl(),
        startDate: new FormControl(),
        incorporationState: new FormControl(),
        businessType: new FormControl(),
        ein: new FormControl(),
        voidedCheckName: new FormControl(),
        addresses: new FormArray([
          new FormGroup({
            id: new FormControl(),
            address1: new FormControl('', Validators.required),
            address2: new FormControl(),
            city: new FormControl('', Validators.required),
            state: new FormControl('', Validators.required),
            zipCode: new FormControl('', Validators.required),
            country: new FormControl()
          })
        ]),
        contacts: new FormArray([
          new FormGroup({
            id: new FormControl(),
            firstName: new FormControl('', Validators.required),
            lastName: new FormControl('', Validators.required),
            email: new FormControl('', [Validators.required, Validators.email]),
            cellNumber: new FormControl('', Validators.required),
            workNumber: new FormControl(),
            ssn: new FormControl(),
            fico: new FormControl(),
            dob: new FormControl(),
            percent: new FormControl(),
            primaryOwner: new FormControl(),
            addresses: new FormArray([
              new Address()
            ])
          })
        ]),
        contact: new FormGroup({
          id: new FormControl(),
          firstName: new FormControl('', Validators.required),
          lastName: new FormControl('', Validators.required),
          email: new FormControl('', { validators: Validators.email, updateOn: 'blur' }),
          cellNumber: new FormControl(),
          workNumber: new FormControl(),
          ssn: new FormControl(),
          fico: new FormControl(),
          dob: new FormControl(),
          percent: new FormControl(),
          primaryOwner: new FormControl()
        })
      }),
      appliedFundingOffer: new FormGroup({
        fundedAmount: new FormControl(),
        buyRate: new FormControl(),
        factorRate: new FormControl(),
        commissionPercent: new FormControl(),
        commissionAmount: new FormControl(),
        paybackAmount: new FormControl(),
        position: new FormControl(),
        paymentAmount: new FormControl(),
        totalPayments: new FormControl(),
        paymentFrequency: new FormControl('Daily'),
        paymentBreakdown: new FormControl(''),
        percentOfGross: new FormControl(),
        termLength: new FormControl(),
        fundingDate: new FormControl()
      }),
      clientId: new FormControl(),
      sendEmailNotifications: new FormControl(),
      sendSmsNotifications: new FormControl(),
      operationsNotes: new FormControl(),
      source: new FormControl(),
      type: new FormControl(),
      whiteLabelId: new FormControl(),
      isoId: new FormControl('', Validators.required),
      isoSalesRepresentativeId: new FormControl(),
    });
  }
}



export class OfferRange {
  id: number;
  applicationId: number;
  minTerm = 1;
  maxTerm = 3;
  minBuyRate = 1;
  maxBuyRate = 2;
  minCommissionPercent = 0;
  maxCommissionPercent = 50;
  minPercentOfGross = 1;
  maxPercentOfGross = 50;
  position = 1;
  fundingOffers = []
  sentOn: number[] | null;
  currentlyActive = false;
  requiresUpdate = false


  serialize(dto: any) {
    this.minBuyRate = dto.minBuyRate;
    this.maxBuyRate = dto.maxBuyRate;
    this.minCommissionPercent = dto.minCommissionPercent;
    this.maxCommissionPercent = dto.maxCommissionPercent;
    this.minPercentOfGross = dto.minPercentOfGross;
    this.maxPercentOfGross = dto.maxPercentOfGross;
    this.minTerm = dto.minTerm;
    this.maxTerm = dto.maxTerm;
    this.position = dto.position;
    this.applicationId = dto.applicationId;
    this.fundingOffers = dto.fundingOffers;
    this.id = dto.id;
    this.sentOn = dto.sentOn ? dto.sentOn : null;
    this.currentlyActive = dto.currentlyActive;
  }
}

export class PostFunding extends FormGroup {
  constructor() {
    super({
      fundingEditDto: new FormGroup({
        amountPaid: new FormControl(),
        applicationId: new FormControl(),
        balance: new FormControl(),
        balanceWithFees: new FormControl(),
        commissionAmount: new FormControl(),
        commissionPercent: new FormControl(),
        disbursementAmount: new FormControl(),
        fundingDate: new FormControl(),
        paybackAmountWithFees: new FormControl(),
        offerBankFeeAmount: new FormControl(),
        offerBankFeePercent: new FormControl(),
      }),
      fundingOfferDto: new FormGroup({
        applicationId: new FormControl(),
        bankFeeAmount: new FormControl(),
        bankFeePercent: new FormControl(),
        buyRate: new FormControl(),
        commissionAmount: new FormControl(),
        commissionPercent: new FormControl(),
        customFeeAmount: new FormControl(),
        expectedStartDate: new FormControl(),
        factorRate: new FormControl(),
        fundedAmount: new FormControl(0, Validators.compose([Validators.required, Validators.min(0.01)])),
        paybackAmount: new FormControl(),
        paymentAmount: new FormControl(),
        paymentBreakdown: new FormControl(),
        paymentFrequency: new FormControl('Daily'),
        percentOfGross: new FormControl(),
        position: new FormControl('', Validators.required),
        sendOffer: new FormControl(),
        sentOn: new FormControl(),
        splitLockBoxExternalId: new FormControl(),
        termLength: new FormControl(),
        totalPayments: new FormControl(),
        transmissionMethod: new FormControl(),
        splitPercent: new FormControl(''),
        wireFeeAmount: new FormControl(),
      }, { updateOn: 'blur' }),
      prepayDtos: new FormArray([])
    });
  }
}

export class PrepaysDto extends FormGroup {
  constructor() {
    super({
      applied: new FormControl(),
      buyRate: new FormControl('', Validators.compose([Validators.required, buyRateLessThanFactorRateValidator])),
      commissionAmount: new FormControl(),
      commissionPercent: new FormControl(),
      factorRate: new FormControl('', Validators.compose([Validators.required, factorRateGreaterThanBuyRateValidator])),
      numberOfDays: new FormControl(),
      paybackAmount: new FormControl(),
      startDay: new FormControl('', Validators.required),
      endDay: new FormControl('', Validators.required),
      term: new FormControl(),
      id: new FormControl()
    });
  }
}

export type Application = {
  id: string;
  amount: number;
  applicationId: number;
  applicationStatus: ApplicationStatus;
  clientId: number;
  clientName: string;
  comparingFieldName: string;
  createdDate: Date;
  isoManagerName: string;
  isoName: string;
  operationsNotes: string;
  submittedDate: Date;
  tenantId: number;
  updatedByName: string;
  lastUpdatedDate: Date;
  applicationType: string;
  userId: number;
  isAccessible: boolean;
};

export type UnderwritingApplication = Application;

export type PreUnderwritingApplication = {
  id: string;
  amount: number;
  applicationId: number;
  applicationStatus: ApplicationStatus;
  clientId: number;
  clientName: string;
  comparingFieldName: string;
  createdDate: Date;
  isoManagerName: string;
  isoName: string;
  operationsNotes: string;
  submittedDate: Date;
  tenantId: number;
  updatedByName: string;
  lastUpdatedDate: Date;
  applicationType: string;
  userId: number;
}

export type OperationsApplication = {
  id: string;
  amount: number;
  applicationId: number;
  applicationStatus: ApplicationStatus;
  clientId: number;
  clientName: string;
  comparingFieldName: string;
  createdDate: Date;
  tenantId: number;
  isAccessible: boolean;
};

export enum ApplicationStatus {
  New = 'NEW',
  Draft = 'DRAFT',
  Credit_Check = 'CREDIT CHECK',
  Operations_Queue = 'OPERATIONS QUEUE',
  Underwriting_Queue = 'UNDERWRITING QUEUE',
  Underwriting = 'UNDERWRITING',
  Application_Revision = 'APPLICATION REVISION',
  Application_Approved = 'APPLICATION APPROVED',
  Offer_Applied = 'OFFER APPLIED',
  Disclosure_Sent = 'DISCLOSURE SENT',
  Disclosure_Signed = 'DISCLOSURE SIGNED',
  Contracts_Sent = 'CONTRACTS SENT',
  Contracts_Received_Incomplete = 'CONTRACTS RECEIVED INCOMPLETE',
  Contracts_Received_Complete = 'CONTRACTS RECEIVED COMPLETE',
  Contracts_Awaiting_Stipulations = 'CONTRACTS AWAITING STIPULATIONS',
  Stipulations_Review = 'STIPULATIONS REVIEW',
  Final_Underwriting = 'FINAL UNDERWRITING',
  Underwriting_Review_Queue = 'UNDERWRITING REVIEW QUEUE',
  Underwriting_Review = 'UNDERWRITING REVIEW',
  Final_Funding_Approval = 'FINAL FUNDING APPROVAL',
  Funded = 'FUNDED',
  No_Go = 'NO GO',
  Declined = 'DECLINED',
  Pre_Underwriting_Queue = 'PRE UNDERWRITING QUEUE',
  Pre_Underwriting = 'PRE UNDERWRITING'
}


export enum ApplicationPriority {
  Regular = 'REGULAR',
  High = 'HIGH'
};


function buyRateLessThanFactorRateValidator(formControl: AbstractControl) {
  if (!formControl.parent) {
    return null;
  }
  const factorRate = formControl.parent.get('factorRate').value;
  const buyRate = formControl.parent.get('buyRate').value;
  if (factorRate && buyRate) {
    if (buyRate > factorRate) {
      return { buyRateGreaterThanFactorRateError: true };
    }
    formControl.parent.get('factorRate').setErrors(null);
    return null;
  }
  return null;
}

function factorRateGreaterThanBuyRateValidator(formControl: AbstractControl) {
  if (!formControl.parent) {
    return null;
  }
  const factorRate = formControl.parent.get('factorRate').value;
  const buyRate = formControl.parent.get('buyRate').value;
  if (factorRate && buyRate) {
    if (factorRate < buyRate) {
      return { factorRateLessThanBuyRateError: true };
    }
    formControl.parent.get('buyRate').setErrors(null);
    return null;
  }
  return null;
}