export function mapApplicationPath(status): string {
    let path = '';
    switch (status) {
      case 'Underwriting':
        path = 'underwriting';
        break;

      case 'Pre_Underwriting':
        path = 'pre-underwriting';
        break;

      case 'Final_Underwriting':
        path = 'final-underwriting';
        break;

      default:
        path = 'overview';
        break;
    }
    return path;
}

export function mapRoleToId(roles, role): string {
  return roles.filter(systemRole => systemRole.name === role)[0].id;
}

export function removeNullValues(obj) {
  for (let key in obj) {
    if (obj[key] === null) {
      delete obj[key];
    } else if (typeof obj[key] === 'object') {
      for (let childKey in obj[key]) {
        if (obj[key][childKey] === null) {
          delete obj[key][childKey];
        }
      }
    }
  }
  for (let key in obj) {
    if (Object.keys(obj[key]).length === 0 && obj[key].constructor === Object) {
      delete obj[key];
    }
  }
  return obj;
}

export function generateUUIDWithTimestamp() {
  const timestamp = new Date().getTime().toString(16); // Get current timestamp in hexadecimal format
  const randomPart = Math.random().toString(16).substring(2, 8); // Generate a random hexadecimal string

  return `${timestamp}-${randomPart}`;
}


export function formatAddress(place) {
  if (!place || !place.address_components) {
    return '';
  }

  const addressComponents = place.address_components;

  const address = {
    streetNumber: '',
    route: '',
    locality: '',
    administrativeArea: '',
    postalCode: '',
    country: ''
  };

  addressComponents.forEach(component => {
    const types = component.types;

    if (types.includes('street_number')) {
      address.streetNumber = component.long_name;
    } else if (types.includes('route')) {
      address.route = component.long_name;
    } else if (types.includes('locality')) {
      address.locality = component.long_name;
    } else if (types.includes('administrative_area_level_1')) {
      address.administrativeArea = component.short_name;
    } else if (types.includes('postal_code')) {
      address.postalCode = component.long_name;
    } else if (types.includes('country')) {
      address.country = component.long_name;
    }
  });

  const formattedAddress =
    address.streetNumber + (address.streetNumber ?  ' ' : '') +
    address.route + (address.route ? ', ' : '') +
    address.locality + (address.locality ? ', ' : '') +
    address.administrativeArea + (address.administrativeArea ? ' ' : '') +
    address.postalCode;

  return formattedAddress;
}

export const isEmailValid = (emailInputValue) => {
  if (emailInputValue) {
    let email = emailInputValue.trim();
    var emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
  }
  return false;
}

export function fillArrayWithIntegresWithRange(m, n) {
  let result = [];
  for (let i = m; i <= n; i++) {
    result.push(i);
  }
  return result;
}

export function roundToSpecificNumberOfDecimal(number, numberOfDecimals): number {
  return parseFloat(number.toFixed(numberOfDecimals));
}

export function toUTCDate(date: Date): Date {
  return new Date(
    Date.UTC(
     date.getFullYear(),
     date.getMonth(),
     date.getDate(),
     date.getHours(),
     date.getMinutes(),
     date.getSeconds(),
     date.getMilliseconds()
    )
  )
}

export function areNumbersEqualWithTolerance(firstNumber: number, secondNumber: number, tolerance: number) {
  return Math.abs(firstNumber - secondNumber) > tolerance
}

export type PropertyComparisonChange = {
  propertyName: string;
  oldValue: any;
  newValue: any;
};

export interface ArrayComparisonChange {
  status: 'Added' | 'Removed' | 'Edited';
  id: string | number;
  changes: Array<PropertyComparisonChange>;
}

export function findChangesInArrays<T extends { id: string | number }>(
  originalArray: T[],
  updatedArray: T[],
  propertiesToCompare: string[]
): ArrayComparisonChange[] {
  if (!originalArray || !updatedArray) return [];

  const updatedArrayMap = new Map(updatedArray.map(item => [item.id, item]));
  const originalArrayMap = new Map(originalArray.map(item => [item.id, item]));
  const changes: ArrayComparisonChange[] = [];

  const allIds = new Set([
    ...originalArray.map(item => item.id),
    ...updatedArray.map(item => item.id),
  ]);

  for (const id of allIds) {
    const originalItem = originalArrayMap.get(id);
    const updatedItem = updatedArrayMap.get(id);

    const keysToCompare = propertiesToCompare ? propertiesToCompare : Object.keys(updatedItem)

    if (!originalItem && updatedItem) {
      const itemChanges = keysToCompare.map((key) => ({
        propertyName: key,
        oldValue: null,
        newValue: updatedItem[key],
      }));
      changes.push({
        status: 'Added',
        id,
        changes: itemChanges,
      });
    } else if (originalItem && !updatedItem) {
      const itemChanges = keysToCompare.map((key) => ({
        propertyName: key,
        oldValue: originalItem[key],
        newValue: null,
      }));
      changes.push({
        status: 'Removed',
        id,
        changes: itemChanges,
      });
    } else if (originalItem && updatedItem) {
      const itemChanges = findChangesBetweenObjects(originalItem, updatedItem, propertiesToCompare);
      if (itemChanges.length > 0) {
        changes.push({
          status: 'Edited',
          id,
          changes: itemChanges,
        });
      }
    }
  }

  return changes;
}



export function findChangesBetweenObjects<T>(
  originalObject: T,
  updatedObject: T,
  propertiesToCompare: string[] = []
): Array<{
  propertyName: string;
  oldValue: any;
  newValue: any;
}> {
  const keysToCompare = propertiesToCompare.length > 0 ? propertiesToCompare : Object.keys(originalObject || {});
  const changes: Array<{
    propertyName: string;
    oldValue: any;
    newValue: any;
  }> = [];

  keysToCompare.forEach((key) => {
    if (!isEqualDeep(originalObject[key], updatedObject[key])) {
      changes.push({
        propertyName: key,
        oldValue: originalObject[key],
        newValue: updatedObject[key]
      });
    }
  });

  return changes;
}

export function isEqualDeep(a: any, b: any, keysToCompare = [], tolerance = 0.00001): boolean {

  if (typeof a === 'number' && typeof b === 'number') {
    return Math.abs(a - b) <= tolerance;
  }

  if (a === b) return true;
  if (a == null || b == null) return false;

  if (Array.isArray(a) && Array.isArray(b)) {
    if (a.length !== b.length) return false;
    return a.every((val, index) => isEqualDeep(val, b[index]));
  }

  if (typeof a === 'object' && typeof b === 'object') {
    if (keysToCompare && keysToCompare.length > 0) {
      return keysToCompare.every(key => isEqualDeep(a[key], b[key]));
    }

    const keysA = Object.keys(a);
    const keysB = Object.keys(b);

    if (keysA.length !== keysB.length) return false;

    return keysA.every(key => isEqualDeep(a[key], b[key]));
  }

  return false;
}
