import { EventEmitter, Injectable } from '@angular/core';
import { map } from 'rxjs/operators';
import { HttpClient } from '@angular/common/http';
import { AbstractService } from './abstract.service';
import { Observable, Subject } from 'rxjs';
import { ScorecardRuleSet } from '@shared/models/score-cards';

@Injectable({
  providedIn: 'root'
})
export class ScoreCardService extends AbstractService {

  eventTriggered = new EventEmitter<any>();

  triggerEvent(data: any) {
    this.eventTriggered.emit(data);
  }

  constructor(
    protected http: HttpClient
  ) {
    super(http);
  }

  getUrl(): string {
    return 'scorecard/';
  }
  
  loadFields(fileName: string) {
    return this.http.put(this.getUrl() + `load-fields?FileName=${fileName}`, {})
      .pipe(map(res => {
        return res;
      }));
  }

  loadRecord(collectionName: string, JsonDoc: any) {
    return this.http.put(this.getUrl() + `load-record?CollectionName=${collectionName}`, JsonDoc)
      .pipe(map(res => {
        return res;
      }));
  }

  loadRecords(fileName: string, collectionName: string) {
    const params = {};
    if (fileName) {
      params['FileName'] = fileName;
    }

    if (collectionName) {
      params['CollectionName'] = collectionName;
    }

    return this.http.put(this.getUrl() + `load-records`, null, { params: params } )
      .pipe(map(res => {
        return res;
      }));
  }

  loadRules(fileName: string) {
    return this.http.put(this.getUrl() + `load-rules?FileName=${fileName}`, {})
      .pipe(map(res => {
        return res;
      }));
  }

  runScoreCard(tenantId: number, applicationId: number) {
    const params = {};
    if (tenantId) {
      params['TenantId'] = tenantId;
    }

    if (applicationId) {
      params['ApplicationId'] = applicationId;
    }

    return this.http.put(this.getUrl() + `run`, null, { params: params } )
      .pipe(map(res => {
        return res;
      }));
  }

  runWithInfo(data: any) {
    return this.http.put(this.getUrl() + `run-with-info`, data )
      .pipe(map(res => {
        return res;
      }));
  }

  loadRuleSet(ruleSet: any) {
    return this.http.put(this.getUrl() + `load-ruleset`, ruleSet)
      .pipe(map(res => {
        return res;
      }));
  }

  loadRuleSets(ruleSets: any) {
    return this.http.put(this.getUrl() + `load-rulesets`, ruleSets)
      .pipe(map(res => {
        return res;
      }));
  }

  listRules(tenantId: number) {
    return this.http.get(this.getUrl() + `tenant/rules?TenantId=${tenantId}`)
      .pipe(map(res => {
        return res;
      }));
  }

  deleteRules(tenantId: number) {
    return this.http.delete(this.getUrl() + `tenant/rules?TenantId=${tenantId}`)
      .pipe(map(res => {
        return res;
      }));
  }

  listRecords(collectionName: string) {
    return this.http.get(this.getUrl() + `tenant/records?CollectionName=${collectionName}`)
      .pipe(map(res => {
        return res;
      }));
  }

  deleteRecords(collectionName: string) {
    return this.http.delete(this.getUrl() + `records?CollectionName=${collectionName}`)
      .pipe(map(res => {
        return res;
      }));
  }

  listDbFields() {
    return this.http.get(this.getUrl() + `tenant/db-fields`)
      .pipe(map(res => {
        return res;
      }));
  }

  deleteDbFields() {
    return this.http.delete(this.getUrl() + `db-fields`)
      .pipe(map(res => {
        return res;
      }));
  }

  public updateRuleSet(tenantId: number, data: any): Observable<ScorecardRuleSet> {
    return this.http.post<ScorecardRuleSet>(`${this.getUrl()}ruleset/tenantId/${tenantId}`, data);
  }

  public deleteRuleByRuleId(ruleId: string): Observable<void> {
    return this.http.delete<void>(`${this.getUrl()}ruleset?Id=${ruleId}`);
  }
}
