<div class="content">
  <div class="content-wrapper">
    <div class="tabbed-tables">
      <div class="heading">
        <h5 [ngClass]="{'active': activeTab === type}" (click)="selectTab(type)" *ngFor="let type of types">{{ type | status | titlecase }}</h5>
      </div>

      <div class="body">
        <div class="clearfix heading">
          <div class="action-buttons">
            <button mat-raised-button color="accent" (click)="editLookupList()">ADD</button>
          </div>
        </div>

        <table mat-table [dataSource]="datasource" matSort multiTemplateDataRows class="clickable">
          <ng-container matColumnDef="select">
            <th mat-header-cell *matHeaderCellDef class="select-cell">
              <mat-checkbox color="primary" (change)="$event ? masterToggle() : null"
                            [checked]="selection.hasValue() && isAllSelected()"
                            [indeterminate]="selection.hasValue() && !isAllSelected()">
              </mat-checkbox>
            </th>
            <td mat-cell *matCellDef="let row" class="select-cell">
              <mat-checkbox color="primary" (click)="$event.stopPropagation()" (change)="$event ? selection.toggle(row) : null"
                            [checked]="selection.isSelected(row)"
                            [disabled]="!row.tenantId">
              </mat-checkbox>
            </td>
          </ng-container>
          <ng-container matColumnDef="value">
            <th mat-header-cell *matHeaderCellDef class="ml-5">Value</th>
            <td mat-cell *matCellDef="let row" class="value-cell"> {{ row.value | type }} </td>
          </ng-container>
          <ng-container matColumnDef="actions">
          <th mat-header-cell *matHeaderCellDef>&nbsp;</th>
          <td mat-cell *matCellDef="let row" class="text-end text-nowrap">
            <div *ngIf="!row.deleting && row.removable">
              <button mat-raised-button color="accent" class="btn-table me-2"
                      (click)="editLookupList(row)">EDIT
              </button>
              <button mat-raised-button color="warn" class="btn-table"
                      (click)="deleteRow(row)">DELETE
              </button>
            </div>
            <div *ngIf="row.deleting">
              Are you sure?
              <a href="#" (click)="$event.preventDefault(); confirmDelete(row)">Yes</a>
              |
              <a href="#" (click)="$event.preventDefault(); cancelDelete(row)">No</a>
            </div>
          </td>
        </ng-container>
          <tr mat-header-row *matHeaderRowDef="columns"></tr>
          <tr mat-row *matRowDef="let row; columns: columns;"></tr>
        </table>
        <oiq-loading-message [isDataTable]="true" [showNoRecordsMessage]="datasource?.data?.length === 0"></oiq-loading-message>
      </div>
    </div>
  </div>
</div>
