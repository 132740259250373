import { Component, OnInit, ViewChild } from '@angular/core';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { MatTable, MatTableDataSource } from '@angular/material/table';
import { SelectionModel } from '@angular/cdk/collections';
import { ActivatedRoute, Router } from '@angular/router';
import { SyndicatorService, TenantService } from '@core/services';
import { AddSyndicatorWalletComponent } from './add/add-wallet.component';
import { AuthService } from '@core/authentication/auth.service';

@Component({
  selector: 'oiq-syndicator-wallets',
  templateUrl: './syndicator-wallets.component.html',
  styleUrls: ['./syndicator-wallets.component.scss']
})
export class SyndicatorWalletsComponent implements OnInit {
  id: number;
  syndicator: any;
  wallets: any = [];
  walletTable: any;
  isSyndicatorUser: boolean;
  walletEnabled: boolean;

  constructor(
    private activatedRoute: ActivatedRoute,
    private syndicatorService: SyndicatorService,
    private router: Router,
    private authService: AuthService,
    public dialog: MatDialog,
    private readonly tenantService: TenantService,
  ) { }

  @ViewChild(MatTable, { static: false }) table: MatTable<any>;

  selection = new SelectionModel(true, []);
  displayedColumns: string[] = ['id', 'whiteLabel', 'balance', 'pendingCredits', 'pendingDebits', 'createdDate', 'updatedDate', 'createdBy', 'updatedBy'];

  ngOnInit() {
    this.tenantService.getSettings(this.authService.getUser().tenantId).subscribe((tenantSettings: any) => {
      this.walletEnabled = tenantSettings.features.SyndicatorWallet;

      if (this.walletEnabled) {
        this.getIsSyndicatorUser();
        this.id = this.isSyndicatorUser ? this.authService.getUser().syndicatorIds[0] : this.activatedRoute.snapshot.params['id'];
        this.syndicatorService.getById(this.id)
          .subscribe((r: any) => {
            if (r) {
              this.syndicator = r;
              this.getWallets();
            }
          });
      } else {
        this.router.navigate(['home']);
      }
    });
  }

  getIsSyndicatorUser() {
    this.isSyndicatorUser = this.authService.getUser().roles.filter((role) => role.name === 'SYNDICATOR').length > 0;
  }

  getWallets() {
    this.syndicatorService.getAllSyndicatorWallets(this.id)
      .subscribe((r: any) => {
        this.wallets = r.content;
        this.walletTable = new MatTableDataSource(this.wallets);
      });
  }

  viewWallet(wallet) {
    if (this.activatedRoute.snapshot.params['id']) {
      this.router.navigate(['syndicators', this.syndicator.id, 'wallets', wallet.id]);
    } else {
      this.router.navigate(['home/syndicator-wallets', wallet.id]);
    }
  }

  addWallet(): void {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.width = '750px';
    dialogConfig.data = {
      whiteLabels: this.syndicator.whiteLabels,
      syndicatorId: this.syndicator.id
    };
    const dialogRef = this.dialog.open(AddSyndicatorWalletComponent, dialogConfig);
    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.getWallets();
      }
    });
  }

}
