import { Component, OnInit, Input } from '@angular/core';
import { FormArray, FormControl, FormGroup, Validators } from '@angular/forms';

import { forkJoin, Subscription } from 'rxjs';

import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { MatTableDataSource } from '@angular/material/table';

import { ApplicationService, FundingService, TransmissionService } from "@core/services";

import { BankInfo, RefinanceForm, RefinancedFunding } from '@core/models';
import { trigger, state, style } from '@angular/animations';
import { AddBankInfoModalComponent } from '@shared/add-bank-info-modal/add-bank-info-modal.component';


@Component({
  selector: 'oiq-refinance',
  templateUrl: './refinance.component.html',
  styleUrls: ['./refinance.component.scss'],
  animations: [
    trigger('detailExpand', [
      state('collapsed, void', style({ height: '0px', minHeight: '0', display: 'none' })),
      state('expanded', style({ height: '*' }))
    ])
  ]
})
export class RefinanceComponent implements OnInit {
  private applicationSubscription: Subscription;

  @Input()
  application: any;

  @Input()
  refinance: boolean;

  whiteLabelId: number;

  editingRefinancedFundings: boolean;

  internalFundingPayments: Array<any> = [];

  IFTotal: number;
  IFPageNumber: number
  IFSize: number;
  IFTotalPages: number;
  IFSelectedApplicationId: number;

  form: any;
  fundings: any[] = [];
  externalFundings: any[] = [];
  refinancedFundings: any[] = [];
  refinanceDatasource: any;
  expandedPaymentsRow: any;
  refinancePayments: any[] = [];
  total: number = 0;
  fundingColumns: string[] = ['date', 'installment', 'amountFunded', 'paybackAmount', 'buyRate', 'factoringRate', 'term', 'position', 'fees', 'balance', 'balanceWithFees', 'paidWithFees'];

  transmissionMethods = [];
  transmissionProcessors = [];
  transmissionMethodList = [];

  refinanceSources: Array<any> = [
    { name: 'Outstanding Balance', value: 'Outstanding_Balance' },
    { name: 'Pending Balance', value: 'Pending_Balance' }
  ];

  routingAccountNumbersVisibleMethods = ['ACH', 'Wire_Transfer'];

  oldSummary: boolean = false;
  hidePendingBalance: boolean;
  hideOutstandingBalance: boolean;
  hideRefinanceSummary: boolean;

  constructor(
    private applicationService: ApplicationService,
    private transmissionService: TransmissionService,
    private fundingService: FundingService,
    public dialog: MatDialog,) {
  }

  ngOnInit() {
    this.whiteLabelId = this.application.whiteLabelId;
    this.transmissionService.getProcessorsByWhiteLabel(this.whiteLabelId, 'Refinance_Bank_Payoff')
      .subscribe((r: any) => {
        this.transmissionMethods = r;
        this.setTransmissionMethodList();
      });

    this.form = new RefinanceForm();
    this.refinanceDatasource = new MatTableDataSource(this.fundings);

    this.applicationSubscription = this.applicationService.application.subscribe((application) => {
      this.application = application;

      this.loadData();
    });
  }

  ngOnDestroy() {
    this.applicationSubscription.unsubscribe();
  }

  onRefinanceSourceChange() {
    this.recalculateRefinancedFundings();
  }

  populateRefinancedFundings() {
    const internalFundings = this.refinancedFundings.filter((row) => row.fundingId);
    const externalFundings = this.refinancedFundings.filter((row) => !row.fundingId);

    if (internalFundings.length > 0 || externalFundings.length > 0) {
      this.refinance = true;
    }

    this.fundings.forEach((funding) => {
      internalFundings.forEach((row) => {
        if (funding.id === row.fundingId) {
          funding.added = true;
          funding.refinancedFundingId = row.id;
        }
      });
    });

    this.form.patchValue(this.application);

    const externalFundingsFormArray = (this.form.get('externalFundings') as FormArray);
    externalFundingsFormArray.clear();

    externalFundings.forEach((row, index) => {
      const externalFunding = new RefinancedFunding();

      if (row.transmissionMethod === 'ACH') {
        externalFunding.addControl('bank', new BankInfo());
        this.setTransmissionMethodProcessors(row.transmissionMethod, index);
        externalFunding.get('transmissionProcessor').setValidators(Validators.required);
      } else if (row.transmissionMethod === 'Wire_Transfer') {
        externalFunding.addControl('bank', new BankInfo());
      }
      externalFunding.patchValue(row);
      externalFunding.patchValue({
        bankId: row.bank.id,
        bank: row.bank,
        routingNumber: row.bank.routingNumber,
        accountNumber: row.bank.accountNumber,
      });
      this.form.updateValueAndValidity();

      externalFundingsFormArray.push(externalFunding);
    });
  }

  loadData() {
    forkJoin([
      this.getFundings(),
      this.getRefinancedFundings()
    ]).subscribe((results: any) => {
      this.fundings = results[0].content.filter(funding => funding.applicationId !== this.application.id);
      this.total = this.fundings.length;
      this.refinanceDatasource = new MatTableDataSource(this.fundings);
      this.refinancedFundings = results[1];

      this.populateRefinancedFundings();

      if (this.application.status === 'Funded') {
        this.recalculatePostFundingRefinancedFundings();
      } else {
        this.recalculateRefinancedFundings();
      }

      this.getFundingBalances();
    });
  }

  getFundings() {
    return this.fundingService.listByCriteria(
      null,
      null,
      null,
      this.application.clientId,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      ['New', 'Funding_Submitted', 'Healthy', 'Payment_Issues', 'Slow_Pay', 'Defaulted', 'Paid_Off'],
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      true
    );
  }

  getFundingBalances() {
    this.fundings.forEach((row) => {
      this.fundingService.getBalances(row.id).subscribe((data) => {
        row.balances = data;
      });
    })
  }

  getRefinancedFundings() {
    return this.applicationService.listRefinancedFundings(this.application.id);
  }

  editRefinancedFundings() {
    this.editingRefinancedFundings = true;

    this.form.get('refinanceBalanceSource').enable();
    this.form.get('refinanceBalanceSourceIncludeFees').enable();

    this.fundingColumns.push('include');
  }

  cancelEditRefinancedFundings() {
    this.editingRefinancedFundings = false;

    this.form.get('refinanceBalanceSource').disable();
    this.form.get('refinanceBalanceSourceIncludeFees').disable();

    this.fundingColumns = this.fundingColumns.splice(0, this.fundingColumns.length - 1);

    this.populateRefinancedFundings();
  }

  addFunding(row) {
    row.added = true;

    this.form.markAsDirty();

    this.recalculateRefinancedFundings();
  }

  removeFunding(row) {
    row.added = false;
    row.deleted = true;

    this.form.markAsDirty();

    this.recalculateRefinancedFundings();
  }

  addExternalFunding() {
    const externalFunding = new RefinancedFunding();
    externalFunding.patchValue({ source: 'External' });

    externalFunding.valueChanges.subscribe((val) => {
      this.recalculateRefinancedFundings();
    });

    (this.form.get('externalFundings') as FormArray).push(externalFunding);

    this.form.markAsDirty();
  }

  removeExternalFunding(row, index) {
    if (row.value.id === null) {
      (this.form.get('externalFundings') as FormArray).removeAt(index);
    } else {
      row.get('deleted').setValue(true);
    }
    this.transmissionProcessors[index] = [];

    this.form.markAsDirty();
  }

  recalculateRefinancedFundings() {
    let totalOutstandingBalance = 0;
    let totalOutstandingBalanceWithFees = 0;
    let totalPendingBalance = 0;
    let totalPendingBalanceWithFees = 0;
    let totalExternalBalance = 0;
    let refinanceAmount = 0;
    let disbursementAmount = 0;
    let fees = 0;
    let observables = [];

    this.fundings.forEach((row) => {
      if (row.added) {
        observables.push(this.fundingService.getBalances(row.id));
      }
    });

    this.form.get('externalFundings')['controls'].forEach(element => {
      totalExternalBalance = totalExternalBalance + element.value.payoffAmount
    });
    this.form.get('externalBalance').setValue(totalExternalBalance);

    if (this.application.appliedFundingOffer && this.application.appliedFundingOffer.fees) {
      fees = this.application.appliedFundingOffer.fees.filter(fee => !fee.includeInBalance && !fee.recurring).reduce((a, b) => {
        return +a + b.amount;
      }, 0);
    }

    if (observables.length > 0) {
      forkJoin(observables).subscribe((results: any) => {
        totalOutstandingBalance = results.reduce((a, b) => {
          return a + b.outstandingBalance;
        }, 0);

        totalOutstandingBalanceWithFees = results.reduce((a, b) => {
          return a + b.outstandingBalanceWithFees;
        }, 0);

        totalPendingBalance = results.reduce((a, b) => {
          return a + b.pendingBalance;
        }, 0);

        totalPendingBalanceWithFees = results.reduce((a, b) => {
          return a + b.pendingBalanceWithFees;
        }, 0);

        totalOutstandingBalanceWithFees += totalExternalBalance
        totalOutstandingBalance += totalExternalBalance
        totalPendingBalance += totalExternalBalance;
        totalPendingBalanceWithFees += totalExternalBalance;

        if (this.form.get('refinanceBalanceSource').value === 'Outstanding_Balance') {
          if (this.form.get('refinanceBalanceSourceIncludeFees').value === true) {
            refinanceAmount = totalOutstandingBalanceWithFees;
          } else {
            refinanceAmount = totalOutstandingBalance;
          }
        } else {
          if (this.form.get('refinanceBalanceSourceIncludeFees').value === true) {
            refinanceAmount = totalPendingBalanceWithFees;
          } else {
            refinanceAmount = totalPendingBalance;
          }
        }

        // if (this.application.status === 'Funded') {
        //   if (this.refinancedFundings.length > 0) {
        //     refinanceAmount = this.refinancedFundings.reduce((a, b) => {
        //       return isNaN(+a + b.payoffAmount) ? 0 : a + b.payoffAmount;
        //     }, 0)
        //   }
        // }

        disbursementAmount = this.application.appliedFundingOffer.fundedAmount - fees - refinanceAmount;

        this.form.get('outstandingBalance').setValue(totalOutstandingBalance);
        this.form.get('outstandingBalanceWithFees').setValue(totalOutstandingBalanceWithFees);
        this.form.get('pendingBalance').setValue(totalPendingBalance);
        this.form.get('pendingBalanceWithFees').setValue(totalPendingBalanceWithFees);
        this.form.get('refinanceAmount').setValue(refinanceAmount);
        this.form.get('disbursementAmount').setValue(disbursementAmount);
      });
    } else {
      totalOutstandingBalance += totalExternalBalance;
      totalOutstandingBalanceWithFees += totalExternalBalance;
      totalPendingBalance += totalExternalBalance;
      totalPendingBalanceWithFees += totalExternalBalance;

      if (this.form.get('refinanceBalanceSource').value === 'Outstanding_Balance') {
        if (this.form.get('refinanceBalanceSourceIncludeFees').value === true) {
          refinanceAmount = totalOutstandingBalanceWithFees;
        } else {
          refinanceAmount = totalOutstandingBalance;
        }
      } else {
        if (this.form.get('refinanceBalanceSourceIncludeFees').value === true) {
          refinanceAmount = totalPendingBalanceWithFees;
        } else {
          refinanceAmount = totalPendingBalance;
        }
      }

      if (this.application.appliedFundingOffer) {
        disbursementAmount = this.application.appliedFundingOffer.fundedAmount - totalOutstandingBalanceWithFees - fees;
      }

      this.form.get('outstandingBalance').setValue(totalOutstandingBalance);
      this.form.get('outstandingBalanceWithFees').setValue(totalOutstandingBalanceWithFees);
      this.form.get('pendingBalance').setValue(totalPendingBalance);
      this.form.get('pendingBalanceWithFees').setValue(totalPendingBalanceWithFees);
      this.form.get('refinanceAmount').setValue(refinanceAmount);
      this.form.get('disbursementAmount').setValue(disbursementAmount);
    }
  }

  recalculatePostFundingRefinancedFundings() {
    console.log('recalculatePostFundingRefinancedFundings');
    let totalOutstandingBalance = 0;
    let totalOutstandingBalanceWithFees = 0;
    let totalPendingBalance = 0;
    let totalPendingBalanceWithFees = 0;
    let totalExternalBalance = 0;
    let refinanceAmount = 0;
    let disbursementAmount = 0;
    let fees = 0;

    this.refinancedFundings.forEach((row) => {
      if (row.source === 'Internal' ) {

        if (!row.outstandingBalance && !row.outstandingBalanceWithFees && !row.pendingBalance && !row.pendingBalanceWithFees) {
          if (this.application.refinanceBalanceSource === 'Pending_Balance') {
            this.hideOutstandingBalance = true;
            totalPendingBalance += row.payoffAmount;
            totalPendingBalanceWithFees += row.payoffAmount;
          } else {
            this.hidePendingBalance = true;
            totalOutstandingBalance += row.payoffAmount;
            totalOutstandingBalanceWithFees += row.payoffAmount;
          }
        } else {
          totalOutstandingBalance += row.outstandingBalance;
          totalOutstandingBalanceWithFees += row.outstandingBalanceWithFees;
          totalPendingBalance += row.pendingBalance;
          totalPendingBalanceWithFees += row.pendingBalanceWithFees;
        }

      } else if (row.source === 'External') {
        totalExternalBalance += row.payoffAmount;
      } else {
        this.hideRefinanceSummary = true;
      }
    });

    if (this.application.appliedFundingOffer && this.application.appliedFundingOffer.fees) {
      fees = this.application.appliedFundingOffer.fees.filter(fee => !fee.includeInBalance && !fee.recurring).reduce((a, b) => {
        return +a + b.amount;
      }, 0);
    }

    totalOutstandingBalanceWithFees += totalExternalBalance
    totalOutstandingBalance += totalExternalBalance
    totalPendingBalance += totalExternalBalance;
    totalPendingBalanceWithFees += totalExternalBalance;

    refinanceAmount = this.application.refinanceAmount;

    disbursementAmount = this.application.appliedFundingOffer.fundedAmount - fees - refinanceAmount;

    this.form.get('externalBalance').setValue(totalExternalBalance);
    this.form.get('outstandingBalance').setValue(totalOutstandingBalance);
    this.form.get('outstandingBalanceWithFees').setValue(totalOutstandingBalanceWithFees);
    this.form.get('pendingBalance').setValue(totalPendingBalance);
    this.form.get('pendingBalanceWithFees').setValue(totalPendingBalanceWithFees);
    this.form.get('refinanceAmount').setValue(refinanceAmount);
    this.form.get('disbursementAmount').setValue(disbursementAmount);
  }

  toggleInternalFundingRow(row) {
    this.expandedPaymentsRow = this.expandedPaymentsRow === row ? null : row;
    if (this.expandedPaymentsRow) {
      this.listTransmissions(
        row.applicationId,
        'Client_Repayment',
        null,
        null,
        25,
        null,
        null
      );
    } else {
      this.IFSelectedApplicationId = null;
    }
  }

  listTransmissions(
    applicationId: number,
    reason: string,
    status: string,
    offset: number,
    limit: number,
    sortBy: string,
    sortDir: string
  ) {
    this.IFSelectedApplicationId = applicationId;

    this.applicationService.listTransmissions(
      applicationId,
      reason,
      status,
      offset,
      limit,
      sortBy,
      sortDir
    )
      .subscribe((r: any) => {
        this.IFTotal = r.totalElements;
        this.IFPageNumber = r.number;
        this.IFSize = r.size;
        this.IFTotalPages = r.totalPages;
        this.internalFundingPayments = r.content;
      });
  }

  changePage(event) {
    this.listTransmissions(
      this.IFSelectedApplicationId,
      'Client_Repayment',
      null,
      event.pageIndex,
      event.pageSize,
      null,
      null
    );
  }

  saveRefinancedFundings() {
    const data = {
      refinanceBalanceSource: this.form.value.refinanceBalanceSource,
      refinanceBalanceSourceIncludeFees: this.form.value.refinanceBalanceSourceIncludeFees,
      refinanceAmount: this.form.value.refinanceAmount,
      refinanceNotes: this.form.value.refinanceNotes,
      refinancedFundings: []
    };

    this.fundings.forEach((row) => {
      if (row.added || row.deleted) {
        data.refinancedFundings.push({
          id: row.refinancedFundingId,
          source: 'Internal',
          fundingId: row.id,
          deleted: row.deleted
        });
      }
    });

    this.form.get('externalFundings').value.forEach((row) => {
      data.refinancedFundings.push(row);
    });

    this.applicationService.createOrUpdateRefinancedFundings(this.application.id, data)
      .subscribe((r: any) => {
        this.cancelEditRefinancedFundings();

        this.applicationService.reloadApplication(this.application.id);
        this.form.markAsPristine();
      });
  }

  calculateFees(fees) {
    return fees.filter(fee => !fee.includeInBalance && !fee.recurring).reduce((a, b) => +a + +b.amount, 0);
  }

  onTransmissionMethodChanged(event, row) {
    this.setTransmissionMethodProcessors(event.value, row);
    const externalFunding = this.form.get('externalFundings')['controls'][row] as FormGroup;
    if (event.value === 'ACH') {
      externalFunding.addControl('bank', new BankInfo());
      externalFunding.get('transmissionProcessor').setValidators([Validators.required]);
      externalFunding.get('transmissionProcessor').setValue(null);
    } else if (event.value === 'Wire_Transfer') {
      externalFunding.addControl('bank', new BankInfo());
      externalFunding.get('transmissionProcessor').clearValidators();
      externalFunding.get('transmissionProcessor').setValue(null);
    } else {
      externalFunding.removeControl('bank');
      externalFunding.get('transmissionProcessor').clearValidators();
      externalFunding.get('transmissionProcessor').setValue(null);
      externalFunding.get('routingNumber').clearValidators();
      externalFunding.get('routingNumber').setValue(null);
      externalFunding.get('accountNumber').clearValidators();
      externalFunding.get('accountNumber').setValue(null);
    }
    this.form.updateValueAndValidity();
  }

  setTransmissionMethodProcessors(methodName: string, index: number) {
    this.transmissionProcessors[index] = this.transmissionMethods.filter((method: any) => method.name === methodName)[0].processors;
  }

  setTransmissionMethodList() {
    this.transmissionMethods.map((obj: any) => {
      this.transmissionMethodList.push({
        name: obj.name.replace('_', ' '),
        value: obj.name
      });
    });
  }

  openBankInfoDialog(item, i): void {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.data = {
      funding: item
    }
    dialogConfig.width = '950px';
    const dialogRef = this.dialog.open(AddBankInfoModalComponent, dialogConfig);
    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        const externalFunding = this.form.get('externalFundings')['controls'][i] as FormGroup;
        externalFunding.addControl('bank', new BankInfo());
        externalFunding.get('bankId').patchValue(result['id']);
        externalFunding.get('bank').patchValue(result);
        externalFunding.get('accountNumber').patchValue(result['accountNumber']);
        externalFunding.get('routingNumber').patchValue(result['routingNumber']);
        this.form.updateValueAndValidity();
        this.form.markAsDirty();
      }
    });
  }

}
