import { Component, Inject, OnInit, ChangeDetectorRef } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { FormGroup, FormArray } from '@angular/forms';

import * as moment from 'moment';
import { RunCreditCheckRequest, Address, addressValidatorRequired } from '@core/models';
import { ContactService, ApplicationService } from '@core/services';
import { toUTCDate } from '@shared/utils';

@Component({
  selector: 'oiq-credit-request-dialog-component',
  templateUrl: './credit-request-dialog-component.html',
  styleUrls: ['./credit-request-dialog-component.scss']
})
export class CreditRequestDialogComponent implements OnInit {
  loading = false;

  form: FormGroup;

  showContacts: boolean;

  applicationId: number;

  application: any;

  contactId: number;

  contact: any;

  contacts: Array<any> = [];

  selectedAddress: any;

  addressModified = false;

  addressesSaving = false;

  constructor(
    private contactService: ContactService,
    private applicationService: ApplicationService,
    public dialogRef: MatDialogRef<CreditRequestDialogComponent>,
    private cdr: ChangeDetectorRef,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {
  }

  cancel(): void {
    this.dialogRef.close();
  }

  ngOnInit() {
    this.application = this.data.application;
    this.applicationId = this.data.application.id;

    this.form = new RunCreditCheckRequest();

    if (this.data.showContacts) {
      this.showContacts = true;

      this.contacts = this.data.contacts;
      this.contact = this.contacts[0];
      this.contactId = this.contact.id;
    } else {
      this.contactId = this.data.contactId;
    }

    this.getContact();
  }

  contactSelected() {
    this.getContact();
  }

  addressSelected(initialize?: boolean) {
    if (this.selectedAddress === 'add') {
      this.selectedAddress = null;
      this.clearAddress();
    }
    if (!initialize) {
      this.addressModified = true;
    }
    this.cdr.detectChanges();
  }

  clearAddress() {
    const addressInstance = new Address();
    addressInstance.setValidators(addressValidatorRequired);
    (this.form.get('addresses') as FormArray).push(addressInstance);
  }

  getContact(addressAdded?: boolean) {
    this.contactService.getById(this.contactId)
      .subscribe((r: any) => {
        this.contact = r;
        this.form.reset({});
        this.form.patchValue(this.contact);

        this.form.get('dob').setValue(moment(this.contact.dob).format('YYYY-MM-DD'));

        if (this.contact.addresses.length > 0) {
          const addressesFormArray = this.form.get('addresses') as FormArray;
          addressesFormArray.clear();
          this.contact.addresses.forEach(address => {
            const formGroup = new Address();
            formGroup.patchValue(address);
            formGroup.setValidators(addressValidatorRequired);
            addressesFormArray.push(formGroup);

          });

          if (!addressAdded) {
            this.selectedAddress = this.form.get('addresses')['controls'][0].value;
          }
          else {
            this.selectedAddress = this.form.get('addresses')['controls'][0].value;
            this.addressModified = false;
            this.addressesSaving = false;
          }
          this.addressSelected(true);
        } else {
          const addressesFormArray = this.form.get('addresses') as FormArray;
          addressesFormArray.clear();
          const formGroup = new Address();
          addressesFormArray.push(formGroup);
          addressesFormArray.controls[0].setValidators(addressValidatorRequired);
          addressesFormArray.controls[0].get('address1').markAsTouched();
          this.cdr.detectChanges();
        }

        this.form.get('contactId').setValue(this.contact.id);
      });
  }

  clearNullValues(formValue) {
    for (let prop in formValue) {
      if (!formValue[prop]) {
        delete formValue[prop];
      }

      if (Array.isArray(formValue[prop])) {
        formValue[prop].forEach(arrayItem => {
          for (let item in arrayItem) {
            if (!arrayItem[item]) {
              delete arrayItem[item];
            }
          }
        });

        let resultArray = formValue[prop].filter(item => Object.keys(item).length !== 0);

        if (resultArray.length > 0) {
          formValue[prop] = resultArray;
        } else {
          delete formValue[prop];
        }
      }
    }
    return formValue;
  }

  saveAddresses() {
    const data = this.clearNullValues(this.form.value);
    delete data.addressId;
    delete data.address;
    this.addressesSaving = true;
    let selectedAddressIndex;

    if (this.selectedAddress && this.selectedAddress.id) {
      selectedAddressIndex = data.addresses.findIndex(address => address.id === this.selectedAddress.id);
    } else {
      selectedAddressIndex = data.addresses.length - 1;
    }

    const selectedAddress = data.addresses.splice(selectedAddressIndex, 1)[0];
    data.addresses.unshift(selectedAddress);

    this.contactService.update(data.contactId, data)
      .subscribe((r: any) => {
        this.getContact(true);
      }
      )
  }

  removeAddress(event) {
    this.addressModified = true;
    const addressesFormArray = this.form.get('addresses') as FormArray;
    addressesFormArray.removeAt(event);
    this.selectedAddress = addressesFormArray.controls[addressesFormArray.controls.length - 1].value;
  }

  submit() {
    this.loading = true;
    let data;

    if (this.data.tenantSettings.creditCheckProvider === 'Crs') {
      data = {
        contactId: this.form.value.contactId
      }
    } else {
      data = this.form.value;
      data = Object.assign(data, data.address);
      if(data && data.dob){
        data.dob = toUTCDate(new Date(data.dob));
      }
    }


    this.applicationService.queueCreditCheckRequest(this.applicationId, data)
      .subscribe((r: any) => {
        this.dialogRef.close(r);
      },
        (error) => {
          this.loading = false;
        },
        () => {
          this.loading = false;
        }
      );
  }
}

