import { map } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { AbstractService } from './abstract.service';
import type { ITenantApplicationType } from '@shared/models/application-type';

@Injectable({
  providedIn: 'root'
})
export class TenantService extends AbstractService {

  constructor(
    protected http: HttpClient
  ) {
    super(http);
  }

  getUrl(): string {
    return 'tenants/';
  }

  listStipulations(id: number) {
    return this.http.get(this.getUrl() + `${id}/stipulations`)
      .pipe(map(res => {
        return res;
      }));
  }

  listUsers(id: number, offset?: number, limit?: number, sortBy?: string, sortDir?: string) {
    const params = super.preparePaginationParams(offset, limit, sortBy, sortDir);

    return this.http.get(this.getUrl() + `${id}/users`, { params })
      .pipe(map(res => {
        return res;
      }));
  }

  listBanks(id: number) {
    return this.http.get(this.getUrl() + `${id}/banks`)
      .pipe(map(res => {
        return res;
      }));
  }

  getSettings(id: number): Observable<any> {
    return this.http.get(this.getUrl() + `${id}/settings`)
      .pipe(map(res => {
        return res;
      }));
  }

  getEvents(id: number, type?: string): Observable<any> {
    let url: string;
    type ? url = `${id}/events/${type}` : url = `${id}/events`
    return this.http.get(this.getUrl() + url)
      .pipe(map(res => {
        return res;
      }));
  }

  updateBilling(id: number, data: any): Observable<any> {
    return this.http.put(this.getUrl() + `${id}/billing`, data)
      .pipe(map(res => {
        return res;
      }));
  }

  updateSettings(id: number, data: any): Observable<any> {
    return this.http.put(this.getUrl() + `${id}/settings`, data)
      .pipe(map(res => {
        return res;
      }));
  }

  updateEvents(id: number, data: any): Observable<any> {
    return this.http.put(this.getUrl() + `${id}/events`, data)
      .pipe(map(res => {
        return res;
      }));
  }

  assignBank(id: number, bankId: number): Observable<any> {
    return this.http.put(this.getUrl() + `${id}/banks/${bankId}`, {})
      .pipe(map(res => {
        return res;
      }));
  }

  addUser(id: number, userId: number): Observable<any> {
    return this.http.put(this.getUrl() + `${id}/users/${userId}`, {})
      .pipe(map(res => {
        return res;
      }));
  }

  deleteUser(id: number, userId: number): Observable<any> {
    return this.http.delete(this.getUrl() + `${id}/users/${userId}`)
      .pipe(map(res => {
        return res;
      }));
  }

  addStipulation(id: number, stipulationId: number) {
    return this.http.put(this.getUrl() + `${id}/stipulations/${stipulationId}`, {})
      .pipe(map(res => {
        return res;
      }));
  }

  removeStipulation(id: number, stipulationId: number) {
    return this.http.delete(this.getUrl() + `${id}/stipulations/${stipulationId}`)
      .pipe(map(res => {
        return res;
      }));
  }

  updateStipulationSetting(id: number, stipulationId: number, data: any) {
    return this.http.put(this.getUrl() + `${id}/stipulations/${stipulationId}/update`, data)
      .pipe(map(res => {
        return res;
      }));
  }

  getProcessorSettings(id: number): Observable<any> {
    return this.http.get(this.getUrl() + `${id}/processor-settings`)
      .pipe(map(res => {
        return res;
      }));
  }

  saveProcessorSettings(id: number, data: any) {
    return this.http.put(this.getUrl() + `${id}/processor-settings`, data)
      .pipe(map(res => {
        return res;
      }));

  }

  updateProcessorSettings(id: number, data: any) {
    return this.http.put(this.getUrl() + `${id}/processor-settings`, data)
      .pipe(map(res => {
        return res;
      }));
  }

  getLogo(id: number) {
    return this.http.get(this.getUrl() + `${id}/logo`)

  }

  saveOcrColumns(id: number, data: any) {
    return this.http.put(this.getUrl() + `${id}/settings/moneythumb-columns`, data)
      .pipe(map(res => {
        return res;
      }));
  }

  getTenantUsersByIdMinimal(id: number) {
    return this.http.get(this.getUrl() + `${id}/users-minimal`)

  }
  
  getOcrColumns(id: number) {
    return this.http.get(this.getUrl() + `${id}/settings/moneythumb-columns`)
      .pipe(map(res => {
        return res;
      }));
  }

  getApplicationTypes(tenantId: number): Observable<ITenantApplicationType[]> {
    return this.http.get<ITenantApplicationType[]>(this.getUrl() + `${tenantId}/settings/application-types`)
      .pipe(map(res => {
        return res;
      }));
  }

  saveApplicationType(tenantId: number, type: string): Observable<ITenantApplicationType> {
    return this.http.put<ITenantApplicationType>(this.getUrl() + `${tenantId}/settings/application-type`, { type })
      .pipe(map(res => {
        return res;
      }));
  }

  editApplicationType(tenantId: number, oldType: string, newType: string): Observable<ITenantApplicationType> {
    return this.http.patch<ITenantApplicationType>(this.getUrl() + `${tenantId}/settings/application-type`, {
      typeToEdit: oldType,
      tenantApplicationTypeDto: {
        type: newType
      }
    })
      .pipe(map(res => {
        return res;
      }));
  }

  removeApplicationType(tenantId: number, type: string): Observable<ITenantApplicationType> {
    return this.http.delete<ITenantApplicationType>(this.getUrl() + `${tenantId}/settings/application-type`, { body: { type } })
      .pipe(map(res => {
        return res;
      }));
  }
}
