<div class="content-wrapper">
  <div class="clearfix heading" *ngIf="wallet.whiteLabelId && walletEnabled">
    <!-- <h2 class="block">{{ wallet?.whiteLabelName }} Wallet</h2> -->
    <div class="block float-start">
      <h2 class="float-none">{{ wallet?.whiteLabelName }} Wallet</h2>
        <a [routerLink]="backToWalletsLink">
          &laquo; Back To Wallets
      </a>
    </div>


    <div class="action-buttons text-end" *authorizeRole="['ADMIN', 'ONYXIQ_ADMIN', 'FULL']">
      <button mat-raised-button color="accent" (click)="addWalletTransaction('Wallet_Credit')">ADD CREDIT</button>
      <button mat-raised-button color="accent" (click)="addWalletTransaction('Wallet_Debit')">ADD DEBIT</button>
    </div>
  </div>

  <div class="row data-boxes">
    <div class="col-sm-12 mx-0 mb-3 text-end">
      <div>White Label: <strong>{{ wallet.whiteLabelName}}</strong></div>
      <div><small><strong>Wallet updated at: {{ wallet.updatedDate | date }}</strong></small></div>
    </div>

    <div class="col-md-4 col-sm-4">
      <div class="col-sm-12 data-box">
        <h3>
          <img src="/assets/images/dollar-icon.svg">
          Total&nbsp;remaining&nbsp;RTR
        </h3>
        <!-- <div class="data-value">{{ "8150" | currency }}</div> -->
        <div class="data-value">{{ (walletStatistics?.totalRemainingRTR | currency) || (0 | currency) }}</div>
        <div>&nbsp;</div>
        <h3>
          <img src="/assets/images/dollar-icon.svg">
          Active&nbsp;remaining&nbsp;RTR
        </h3>
        <div class="data-value">{{ (walletStatistics?.activeRemainingRTR | currency) || (0 | currency) }}</div>
      </div>
    </div>

    <div class="col-md-4 col-sm-6">
      <div class="col-sm-12 data-box">
        <h3>
          <img src="/assets/images/contracts-icon.svg">
          TRANSACTIONS
        </h3>
        <div class="data-box-section" *ngIf="walletStatistics?.transactionsHistory; else transmissionsLoading">
          <div class="breakdown-row">
            <span><strong>( Last 9 Months )</strong></span>
          </div>
          <div class="breakdown-row">
            <span>Syndicator Payments:</span>
            <span class="right-align"><strong> ({{walletStatistics?.transactionsHistory?.syndicatorPayments?.count || 0}}) {{ (walletStatistics?.transactionsHistory?.syndicatorPayments?.total || 0) | currency }}</strong></span>
          </div>
          <div class="breakdown-row">
            <span>Syndicator Repayments:</span>
            <span class="right-align"><strong> ({{walletStatistics?.transactionsHistory?.syndicatorRepayments?.count || 0}}) {{ (walletStatistics?.transactionsHistory?.syndicatorRepayments?.total || 0) | currency }}</strong></span>
          </div>
          <div class="breakdown-row">
            <span>Wallet Credit:</span>
            <span class="right-align"><strong> ({{walletStatistics?.transactionsHistory?.walletCredit?.count || 0}}) {{ (walletStatistics?.transactionsHistory?.walletCredit?.total || 0) | currency }}</strong></span>
          </div>
          <div class="breakdown-row">
            <span>Wallet Debit:</span>
            <span class="right-align"><strong> ({{walletStatistics?.transactionsHistory?.walletDebit?.count || 0}}) {{ (walletStatistics?.transactionsHistory?.walletDebit?.total || 0) | currency }}</strong></span>
          </div>
        </div>
        <ng-template #transmissionsLoading>
          <div class="data-box-section">
            <div class="loader">
              <img width="36" height="36" src="/assets/images/loading-spinner-full.svg">
            </div>
          </div>
        </ng-template>
      </div>
    </div>

    <div class="col-md-4 col-sm-6">
      <div class="col-sm-12 data-box">
        <h3>
          <img src="/assets/images/contracts-icon.svg">
          BALANCE {{ (wallet.balance || 0) | currency}}
        </h3>
        <div class="data-box-section" *ngIf="wallet?.id; else balanceLoading">
          <span>
            <div>Pending Balance: {{ (wallet.availableBalance || 'N/A') | currency}}</div>
          </span>
          <div class="data-graph p-0 m-0" style="max-height: 240px; overflow: hidden;">
            <canvas id="balancesChart" width="1600" height="900" #balancesChart></canvas>
          </div>
        </div>
        <ng-template #balanceLoading>
          <div class="data-box-section">
            <div class="loader">
              <img width="36" height="36" src="/assets/images/loading-spinner-full.svg">
            </div>
          </div>
        </ng-template>
      </div>
    </div>
  </div>

  <div class="body scrollable pt-3">
    <table mat-table [dataSource]="transmissionsTable" matSort>
      <ng-container matColumnDef="id">
        <th mat-header-cell *matHeaderCellDef>Transaction ID</th>
        <td mat-cell *matCellDef="let row"> {{row.id}} </td>
      </ng-container>
      <ng-container matColumnDef="method">
        <th mat-header-cell *matHeaderCellDef>Method</th>
        <td mat-cell *matCellDef="let row"> {{row.method | status}} </td>
      </ng-container>
      <ng-container matColumnDef="reason">
        <th mat-header-cell *matHeaderCellDef>Reason</th>
        <td mat-cell *matCellDef="let row"> {{row.reason | status}} </td>
      </ng-container>
      <ng-container matColumnDef="createdDate">
        <th mat-header-cell *matHeaderCellDef>Date Created</th>
        <td mat-cell *matCellDef="let row" class="nowrap"> {{row.createdDate | date}} </td>
      </ng-container>
      <ng-container matColumnDef="hitDate">
        <th mat-header-cell *matHeaderCellDef>Hit Date</th>
        <td mat-cell *matCellDef="let row"> {{row.hitDate | date}} </td>
      </ng-container>
      <ng-container matColumnDef="settlementDate">
        <th mat-header-cell *matHeaderCellDef>Settlement Date</th>
        <td mat-cell *matCellDef="let row"> {{row.settlementDate | date}} </td>
      </ng-container>
      <ng-container matColumnDef="returnDate">
        <th mat-header-cell *matHeaderCellDef>Return Date</th>
        <td mat-cell *matCellDef="let row"> {{row.returnDate | date}} </td>
      </ng-container>
      <ng-container matColumnDef="balanceBefore">
        <th mat-header-cell *matHeaderCellDef>Balance Before</th>
        <td mat-cell *matCellDef="let row"> {{row.balanceBefore | currency}} </td>
      </ng-container>
      <ng-container matColumnDef="amount">
        <th mat-header-cell *matHeaderCellDef>Amount</th>
        <td mat-cell *matCellDef="let row"> {{row.amount | currency}} </td>
      </ng-container>
      <ng-container matColumnDef="balance">
        <th mat-header-cell *matHeaderCellDef>Balance</th>
        <td mat-cell *matCellDef="let row"> {{row.balance | currency}} </td>
      </ng-container>
      <ng-container matColumnDef="status">
        <th mat-header-cell *matHeaderCellDef>Status</th>
        <td mat-cell *matCellDef="let row"> {{row.status | status}} </td>
      </ng-container>
      <ng-container matColumnDef="responseDescription">
        <th mat-header-cell *matHeaderCellDef>Response Description</th>
        <td mat-cell *matCellDef="let row"> {{row.responseDescription || 'N/A'}} </td>
      </ng-container>
      <ng-container matColumnDef="updatedDate">
        <th mat-header-cell *matHeaderCellDef>Updated Date</th>
        <td mat-cell *matCellDef="let row"> {{row.updatedDate | date}} </td>
      </ng-container>
      <ng-container matColumnDef="createdBy">
        <th mat-header-cell *matHeaderCellDef>Created By</th>
        <td mat-cell *matCellDef="let row"> {{row.createdBy.firstName ? (row.createdBy | fullName) : 'System'}} </td>
      </ng-container>
      <ng-container matColumnDef="updatedBy">
        <th mat-header-cell *matHeaderCellDef>Updated By</th>
        <td mat-cell *matCellDef="let row"> {{row.updatedBy.firstName ? (row.updatedBy | fullName) : 'System'}} </td>
      </ng-container>
      <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
    </table>
    <oiq-loading-message [isDataTable]="true" [showNoRecordsMessage]="transmissionsTable?.data?.length === 0"></oiq-loading-message>
    <mat-paginator [length]="total" [pageIndex]="currentPage" [pageSize]="pageSize" [pageSizeOptions]="[25, 50, 100]"></mat-paginator>
  </div>
</div>
