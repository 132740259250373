<mat-accordion *ngIf="mtInsights">
  <mat-expansion-panel *ngFor="let account of formattedMTAccounts; let i = index;" [expanded]="i === 0"
    (opened)="panelOpenState = true" (closed)="panelOpenState = false">
    <mat-expansion-panel-header>
      <mat-panel-title>{{ account[1][0].bank_name }} ({{ account[0] }})</mat-panel-title>
    </mat-expansion-panel-header>

    <div class="scrollable wide-table">
      <table mat-table [dataSource]="account[1]" class="table my-3" multiTemplateDataRows>

        <ng-container matColumnDef="toggle">
          <th mat-header-cell *matHeaderCellDef>Transactions</th>
          <td mat-cell *matCellDef="let row">
            <div class="expand-cell" (click)="$event.stopPropagation(); toggleTransactions(row, 'transactions')"
              *ngIf="row.transactions?.length">
              <i class="material-icons arrow"
                [ngClass]="{ 'active': expandedElement === row && expandedType === 'transactions' }">play_arrow</i>
            </div>
          </td>
          <td mat-footer-cell *matFooterCellDef><strong>Average</strong></td>
        </ng-container>

        <ng-container *ngFor="let column of displayedColumns">
          <ng-container [matColumnDef]="column">
            <th mat-header-cell *matHeaderCellDef [class.date-column]="getPipe(column) === 'date'">
              {{ column | columnName: availableColumns }}
            </th>

            <td mat-cell *matCellDef="let row"
              [class.cell-inspection-recommended]="column === 'thumbprint_score' && thumbPrintEnabled && row.thumbprint_score >= 400 && row.thumbprint_score < 700"
              [class.cell-significant-fraud]="column === 'thumbprint_score' && thumbPrintEnabled && row.thumbprint_score >= 700"
              [class.cell-expandable-factors]="column === 'thumbprint_score' && thumbPrintEnabled && row.thumbprint_score > 0"
              matTooltip="{{ column === 'thumbprint_score' ? getThumbprintTooltip(row.thumbprint_score) : '' }}">
              <ng-container [ngSwitch]="getPipe(column)">
                <span *ngSwitchCase="'number'">
                  <ng-container *ngIf="column === 'thumbprint_score'; else regularNumber">
                    <div class="expand-cell" *ngIf="row[column] > 0 && thumbPrintEnabled"
                      (click)="$event.stopPropagation(); toggleTransactions(row, 'fraud')">
                      <i class="material-icons arrow"
                        [ngClass]="{ 'active': expandedElement === row && expandedType === 'fraud' }">
                        play_arrow
                      </i>
                    </div>
                    <span>
                      {{ row[column] !== undefined && row[column] !== null ? (row[column] | number: '1.0-0') : '-' }}
                    </span>
                  </ng-container>
                  <ng-template #regularNumber>
                    {{ row[column] !== undefined && row[column] !== null ? row[column] : '-' }}
                  </ng-template>
                </span>

                <span *ngSwitchCase="'currency'">
                  {{ row[column] !== undefined && row[column] !== null ? (row[column] | currency) : '-' }}
                </span>

                <span *ngSwitchCase="'date'">
                  {{ row[column] !== undefined && row[column] !== null ? (row[column] | date) : '-' }}
                </span>

                <span *ngSwitchDefault>
                  {{ row[column] !== undefined && row[column] !== null ? row[column] : '-' }}
                </span>
              </ng-container>
            </td>

            <td mat-footer-cell *matFooterCellDef>
              <ng-container [ngSwitch]="getPipe(column)">
                <span *ngIf="column === 'toggle'"><strong>Average</strong></span>

                <span *ngSwitchCase="'currency'">
                  {{ accountAverages[account[0]]?.[column] !== null
                  ? (accountAverages[account[0]]?.[column] | currency)
                  : '-' }}
                </span>

                <span *ngSwitchCase="'number'">
                  {{ accountAverages[account[0]]?.[column] !== null
                  ? accountAverages[account[0]]?.[column]
                  : '-' }}
                </span>

                <span *ngSwitchDefault>
                  {{ accountAverages[account[0]]?.[column] !== null
                  ? accountAverages[account[0]]?.[column]
                  : '-' }}
                </span>
              </ng-container>
            </td>
          </ng-container>
        </ng-container>

        <ng-container matColumnDef="expandedDetail">
          <td mat-cell *matCellDef="let row" [attr.colspan]="fullDisplayedColumns.length">
            <div class="row-details" [@detailExpand]="row == expandedElement ? 'expanded' : 'collapsed'">
              <div *ngIf="expandedElement === row && expandedType === 'transactions'">
                <table class="inner-table">
                  <thead>
                    <tr>
                      <th>Date</th>
                      <th>Payee</th>
                      <th>Amount</th>
                      <th>Memo</th>
                      <th>True credit</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr *ngFor="let transaction of row.transactions">
                      <td>{{ transaction.date | date: 'shortDate' }}</td>
                      <td>{{ transaction.description }}</td>
                      <td>{{ transaction.amount | currency }}</td>
                      <td>{{ transaction.memo }}</td>
                      <td class="align-center">
                        <mat-icon *ngIf="transaction.trueval"
                          [ngClass]="{ 'green': transaction.trueval }">check_circle</mat-icon>
                        <mat-icon *ngIf="!transaction.trueval"
                          [ngClass]="{ 'red': !transaction.trueval }">cancel</mat-icon>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>

              <div *ngIf="expandedElement === row && expandedType === 'fraud'">
                <h4>Fraud Score Contributing Factors</h4>
                <ul>
                  <li *ngFor="let factor of getFraudFactors(row)">
                    {{ factor }}
                  </li>
                </ul>
              </div>
            </div>
          </td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="fullDisplayedColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: fullDisplayedColumns"
          [class.row-inspection-recommended]="displayedColumns.includes('thumbprint_score') && thumbPrintEnabled && row.thumbprint_score >= 400 && row.thumbprint_score < 700"
          [class.row-significant-fraud]="displayedColumns.includes('thumbprint_score') && thumbPrintEnabled && row.thumbprint_score >= 700 && row.thumbprint_score <= 1000">
        </tr>
        <tr mat-row *matRowDef="let row; columns: ['expandedDetail']" class="detail-row"></tr>
        <tr mat-footer-row *matFooterRowDef="fullDisplayedColumns"></tr>
      </table>
    </div>
  </mat-expansion-panel>
</mat-accordion>