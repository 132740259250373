import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { RequestDocsDialogComponent } from '../../user/clients/view/add-application/request-docs-dialog/request-docs-dialog.component';
import { StipulationNotesDialogComponent } from './stipulation-notes-dialog/stipulation-notes-dialog.component';
import { ApplicationEventConfirmationDialogComponent } from '@shared/application-event-confirmation-dialog/application-event-confirmation-dialog.component';
import { AttachmentService, ApplicationService } from '@core/services';
import { MatDialog } from '@angular/material/dialog';
import { state, style, trigger } from '@angular/animations';

import { concat, Subject } from 'rxjs';

import { environment } from '@environments/environment';
import { concatAll, switchMap, takeUntil } from 'rxjs/operators';

import { Stipulation, StipulationStatus } from '@shared/model/Stipulation';
@Component({
  selector: 'oiq-stipulations',
  templateUrl: './stipulations.component.html',
  styleUrls: ['./stipulations.component.scss'],
  animations: [
    trigger('detailExpand', [
      state('collapsed, void', style({height: '0px', minHeight: '0', display: 'none'})),
      state('expanded', style({height: '*'}))
    ])
  ],
})
export class StipulationsComponent implements OnInit, OnDestroy {
  private destroy$ = new Subject<void>();

  @Input() application: any;
  @Input() disabled: boolean;
  @Input() updateStipulations: Subject<boolean>
  @Output() uploadSuccess = new EventEmitter();
  @Output() requesetedAdditionalDocs = new EventEmitter();
  @Output() stipulationListUpdated: EventEmitter<any> = new EventEmitter<any>();

  stipulationsTable = new MatTableDataSource<Stipulation>([]);

  displayedStipulationsColumns: string[] = ['name', 'actions', 'notes', 'status', 'excludeFromEmail'];
  expandedStipulationRow: any;
  stipStatuses: Array<any> = [
    {'name': 'Requested', 'value': 'Requested'},
    {'name': 'Received', 'value': 'Received'},
    {'name': 'Verified', 'value': 'Verified'},
    {'name': 'Waived', 'value': 'Waived'}
  ];

  stipulationsAwaitingUpload: number = 0;

  uploadUrl = `${environment.servicesUrl}/attachments/upload?type=application`;

  constructor(
    public dialog: MatDialog,
    private attachmentService: AttachmentService,
    private applicationService: ApplicationService,
  ) {
  }

  ngOnInit() {
    this.getStipulations(this.application.id);
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  ngOnChanges() {
    this.updateStipulations.subscribe(() => {
      this.getStipulations(this.application.id);
    });
  }

  onUploadSuccess(stipulation, attachments) {
    this.applicationService.addAttachments(this.application.id, attachments.map(a => a.id))
      .subscribe(() => {
        concat(attachments.map(a => this.applicationService.associateAttachmentToStipulation(this.application.id, stipulation.id, a.id)))
        .pipe(concatAll()).subscribe(r=> {
          this.uploadSuccess.emit();
          this.getStipulations(this.application.id)
        })
      });
  }

  downloadFile(attachmentId) {
    this.attachmentService.openInWindowById(attachmentId);
  }

  additionalDocsDialog() {
    const docsDialog = this.dialog.open(RequestDocsDialogComponent, {
      width: '800px',
      data: this.application.id
    });

    docsDialog.afterClosed().subscribe(result => {
      this.requesetedAdditionalDocs.emit();
      this.getStipulations(this.application.id);
    });
  }

  toggleStipulationRow(row) {
    this.expandedStipulationRow = this.expandedStipulationRow === row ? null : row;
  }

  toggleExcludeFromEmail(item, event) {
    item.excludeFromEmail = event.checked;

    this.applicationService.updateStipulation(this.application.id, item.id, item).subscribe((r: any) => {
        this.applicationService.reloadApplication(this.application.id);
      });
  }

  changeStipStatus(applicationId: number, stipulationId: number, status: StipulationStatus): void {
    if (status === 'Waived') {
      this.dialog.open(StipulationNotesDialogComponent)
        .afterClosed()
        .pipe(
          switchMap(({ notes }) => this.applicationService.getUpdatedStipulationsWithStatus(applicationId, stipulationId, status, notes, this.stipulationsTable.data)),
          takeUntil(this.destroy$)
        )
        .subscribe((updatedData) => {
          this.stipulationsTable.data = updatedData;
        });

        return;
    }

    this.applicationService.getUpdatedStipulationsWithStatus(applicationId, stipulationId, status, null, this.stipulationsTable.data)
      .pipe(takeUntil(this.destroy$))
      .subscribe((updatedData) => {
        this.stipulationsTable.data = updatedData;
      });
  }

  sendUpdatedStipulationsList() {
    const dialogRef = this.dialog.open(ApplicationEventConfirmationDialogComponent, {
      width: '800px',
      data: {
        title: 'Send Updated Stipulations List',
        event: 'Send_Stipulations_List',
        application: this.application
      }
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        const data = {
          event: 'Send_Stipulations_List',
          metadata: result
        };


        this.applicationService.sendNotification(this.application.id, data).subscribe((data) => {
          this.applicationService.reloadApplication(this.application.id);
        });
        this.getStipulations(this.application.id);
      }
    });
  }

  getApplication(id) {
    this.applicationService.getById(id)
      .subscribe((r: any) => {
          this.application = r;

          this.stipulationsTable = new MatTableDataSource(this.application.stipulations);
        });
  }

  getStipulations(applicationId) {
    this.applicationService.listApplicationStipulations(applicationId)
      .subscribe((r: any) => {
        this.application.stipulations = r;
        this.stipulationsTable = new MatTableDataSource(this.application.stipulations);

        const awaitingUpload = this.application.stipulations.filter((row) => {
          return row.status === 'Requested' && !row.excludeFromEmail;
        });
        this.stipulationListUpdated.emit(r);
        this.stipulationsAwaitingUpload = awaitingUpload.length;
      });
  }
}
