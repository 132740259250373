import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'columnName'
})
export class OcrColumnNamePipe implements PipeTransform {
  constructor() {}

  transform(columnValue: string, availableColumns: any[]): string {
    const column = availableColumns.find(col => col.value === columnValue);
    return column ? column.name : columnValue;
  }
}