import { FormControl, FormGroup, FormArray, Validators } from '@angular/forms';

export class Payment extends FormGroup {
  constructor() {
    super({
      fundedAmount: new FormControl(),
      buyRate: new FormControl(),
      factorRate: new FormControl(),
      termLength: new FormControl(),
      commissionAmount: new FormControl(),
      commissionPercent: new FormControl(),
      paybackAmount: new FormControl(),
      paymentAmount: new FormControl(),
      paymentBreakdown: new FormControl(),
      paymentFrequency: new FormControl('Daily'),
      percentOfGross: new FormControl(),
      position: new FormControl('', Validators.required),
      totalPayments: new FormControl(),
      updatedField: new FormControl(),
    }, { updateOn: 'blur' });
  }
}

export class Fee extends FormGroup {
  constructor(feeId?: number, amount?: number) {
    super({
      feeId: new FormControl(feeId, Validators.required),
      amount: new FormControl(amount ? amount : 0, Validators.required)
    });
  }
}

export class FundingSyndication extends FormGroup {
  constructor(fundingSyndicationId?: number, amount?: number) {
    super({
      fundingSyndicationId: new FormControl(fundingSyndicationId, Validators.required),
      amount: new FormControl(amount ? amount : 0, Validators.required)
    });
  }
}

export enum AutomaticPaymentStatus {
  ACTIVE = 'Active',
  PAUSED = 'Paused'
}
