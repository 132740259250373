<div>
  <div class="mat-dialog-title-wrapper">
    <h1 mat-dialog-title>Confirm your action</h1>
    <button mat-button class="close-dialog" mat-dialog-close>
      <i class="material-icons">clear</i>
    </button>
  </div>
  <div mat-dialog-content>
    <div class="row">
      <div class="col-sm-12" [innerHTML]="message">

      </div>
    </div>
  </div>
  <div mat-dialog-actions>
    <button mat-stroked-button type="button" (click)="decline()">No</button>
    <button mat-raised-button color="accent" type="button" (click)="confirm()">Yes</button>
  </div>
</div>
