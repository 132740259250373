import { catchError, map, tap } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, BehaviorSubject, of } from 'rxjs';
import { AbstractService } from './abstract.service';
import { environment } from '../../../environments/environment';
import { Application, OfferRange } from '@core/models';
import { ApiResponse } from '@core/models/shared';
import { StipulationStatus, Stipulation } from '@shared/model/Stipulation';

@Injectable({
    providedIn: 'root'
})
export class ApplicationService extends AbstractService {

  private _application: BehaviorSubject<any> = new BehaviorSubject<any>(null);
  public application$ = this._application.asObservable();

  constructor(
    protected http: HttpClient
  ) {
    super(http);
  }

  getUrl(): string {
    return 'applications/';
  }

  setApplicationObject(data: any) {
    this._application.next(data);
  }

  get application(): BehaviorSubject<any> {
    return this._application;
  }

  getById(id: number): Observable<any> {
    return super.getById(id)
      .pipe(map(res => {
        this.application.next(res);

        return res;
      }));
  }

  getMinApplications(id: number): Observable<any> {
    return this.http.get(this.getUrl() + `/${id}/applications-minimal`)
      .pipe(map(res => {
        return res;
      }));
  }



  reloadApplication(id: number, async: boolean = false): Observable<any> {
    if (!async) {
      this.getById(id).subscribe();
    } else {
      return this.getById(id);
    }
  }

  clearApplication() {
    this.application.next(null);
  }

  listByCriteria(ids: string, description: string, fundingAmountStart: number, fundingAmountEnd: number, percentPaidInMin: number, percentPaidInMax: number, clientId, clientName: string, types: string, whiteLabelId: number, statuses: string, fundingStatuses: string, isoIds: Array<any>, isoManagerIds: Array<any>, startDate: string, endDate: string, clientLegalName?:string, phoneNumber?: string, email?: string, showHighPriorityDeal?: boolean, offset?: number, limit?: number, sortBy?: string, sortDir?: string): Observable<any> {
    const params = super.preparePaginationParams(offset, limit, sortBy, sortDir);

    if (ids) { params['ids'] = ids.toString(); }
    if (description) { params['description'] = description.toString(); }
    // if (fundingAmountStart) { params['fundingAmountStart'] = fundingAmountStart.toString(); }
    // if (fundingAmountEnd) { params['fundingAmountEnd'] = fundingAmountEnd.toString(); }
    if (percentPaidInMin) { params['percentPaidInMin'] = percentPaidInMin; }
    if (percentPaidInMax) { params['percentPaidInMax'] = percentPaidInMax; }
    if (clientId) { params['clientId'] = clientId; }
    if (clientName) { params['clientName'] = clientName; }
    if (types) { params['types'] = types; }
    if (whiteLabelId) { params['whiteLabelId'] = whiteLabelId; }
    if (statuses) { params['statuses'] = statuses; }
    if (fundingStatuses) { params['fundingStatuses'] = fundingStatuses; }
    if (isoIds && isoIds.length > 0) { params['isoIds'] = isoIds.join(','); }
    if (isoManagerIds && isoManagerIds.length > 0) { params['isoManagerIds'] = isoManagerIds.join(','); }
    if (startDate) { params['startDate'] = startDate; }
    if (endDate) { params['endDate'] = endDate; }
    if (clientLegalName) { params['clientLegalName'] = clientLegalName; }
    if (phoneNumber) { params['phoneNumber'] = phoneNumber.toString(); }
    if (email) { params['email'] = email.toString(); }
    if (showHighPriorityDeal) { params['urgent'] = showHighPriorityDeal }

    return this.http.get(this.getUrl(), { params: params })
      .pipe(map(res => {
        return res;
      }));
  }

  unfilteredList(offset?: number, limit?: number, sortBy?: string, sortDir?: string): Observable<any> {
    const params = super.preparePaginationParams(offset, limit, sortBy, sortDir);

    return this.http.get(this.getUrl() + 'unfiltered', {params: params})
      .pipe(map(res => {
        return res;
      }));
  }

  listFinalApprovals(offset?: number, limit?: number, sortBy?: string, sortDir?: string) {
    const params = super.preparePaginationParams(offset, limit, sortBy, sortDir);

    return this.http.get(this.getUrl() + `final-approvals`, { params })
      .pipe(map(res => {
        return res;
      }));
  }

  listSyndications(id: number, offset?: number, limit?: number, sortBy?: string, sortDir?: string) {
    const params = super.preparePaginationParams(offset, limit, sortBy, sortDir);

    return this.http.get(this.getUrl() + `syndications/${id}`, { params })
      .pipe(map(res => {
        return res;
      }));
  }

  listTransmissions(id: number, reason?: string, status?: string, offset?: number, limit?: number, sortBy?: string, sortDir?: string) {
    const params = super.preparePaginationParams(offset, limit, sortBy, sortDir);
    if (reason) { params['reason'] = reason; }
    if (status) { params['status'] = status; }

    return this.http.get(this.getUrl() + `${id}/transmissions`, { params })
      .pipe(map(res => {
        return res;
      }));
  }

  listApplicationsByClientId(clientId?: number, offset?: number, limit?: number, sortBy?: string, sortDir?: string): Observable<any> {
    const params = super.preparePaginationParams(offset, limit, sortBy, sortDir);
    if (clientId) { params['clientId'] = clientId.toString(); }

    return this.http.get(this.getUrl(), { params: params })
      .pipe(map(res => {
        return res;
      }));
  }

  updateStipulationStatus(applicationId: number, applicationStipulationId: number, status: string, notes: string) {
    return this.http.put(this.getUrl() + `${applicationId}/stipulations/${applicationStipulationId}/${status}`, { notes })
      .pipe(map(res => {
        return res;
      }));
  }

  listAttachments(id: number, source: string, category?: string, offset?: number, limit?: number, sortBy?: string, sortDir?: string) {
    const params = super.preparePaginationParams(offset, limit, sortBy, sortDir);
    if (source) { params['source'] = source; }
    if (category) { params['category'] = category; }

    return this.http.get(this.getUrl() + `${id}/attachments`, { params })
      .pipe(map(res => {
        return res;
      }));
  }

  listApplicationStipulations(applicationId: number) {
    return this.http.get(this.getUrl() + `${applicationId}/stipulations`)
      .pipe(map(res => {
        return res;
      }));
  }

  listBanks(applicationId: number) {
    return this.http.get(this.getUrl() + `${applicationId}/banks`)
      .pipe(map(res => {
        return res;
      }));
  }

  listCreditChecks(applicationId: number, mockData?: boolean) {
    if (mockData) {
      return this.http.get('https://app.onyxiq.com/assets/mock-data/experian-credit-checks.json')
      .pipe(map(res => {
        return res;
      }));
    } else {
    return this.http.get(this.getUrl() + `${applicationId}/credit-checks`)
      .pipe(map(res => {
        return res;
      }));
    }
  }

  listDecisionLogicRequests(applicationId: number, mockData?: boolean) {
    if (mockData) {
      return this.http.get('https://app.onyxiq.com/assets/mock-data/decision-logic-reports.json')
      .pipe(map(res => {
        return res;
      }));
    } else {
    return this.http.get(this.getUrl() + `${applicationId}/decisionlogic`)
      .pipe(map(res => {
        return res;
      }));
    }
  }

  listSyndicationOffers(applicationId: number, offset?: number, limit?: number, sortBy?: string, sortDir?: string) {
    const params = super.preparePaginationParams(offset, limit, sortBy, sortDir);
    return this.http.get(this.getUrl() + `${applicationId}/syndications/offers/`, { params })
      .pipe(map(res => {
        return res;
      }));
  }

  listRefinancedFundings(applicationId: number) {
    return this.http.get(this.getUrl() + `${applicationId}/refinanced-fundings`)
      .pipe(map(res => {
        return res;
      }));
  }

  listStatuses(applicationId: number, offset?: number, limit?: number, sortBy?: string, sortDir?: string) {
    const params = super.preparePaginationParams(offset, limit, sortBy, sortDir);

    return this.http.get(this.getUrl() + `${applicationId}/statuses/`, { params })
      .pipe(map(res => {
        return res;
      }));
  }

  listContractStatuses(applicationId: number, offset?: number, limit?: number, sortBy?: string, sortDir?: string) {
    const params = super.preparePaginationParams(offset, limit, sortBy, sortDir);

    return this.http.get(this.getUrl() + `${applicationId}/contract/statuses/`, { params })
      .pipe(map(res => {
        return res;
      }));
  }

  createAndDecline(data: any): Observable<any> {
    return this.http.post(this.getUrl() + 'decline', data)
      .pipe(map(res => {
        return res;
      }));
  }

  getCreditCheck(applicationId: number, creditCheckId: number, mockData?: boolean) {
    if (mockData) {
      return this.http.get('https://app.onyxiq.com/assets/mock-data/experian-report.json')
      .pipe(map(res => {
        return res;
      }));
    } else {
    return this.http.get(this.getUrl() + `${applicationId}/credit-checks/${creditCheckId}`)
      .pipe(map(res => {
        return res;
      }));
    }
  }

  queueCreditCheckRequest(applicationId: number, data: any) {
    return this.http.post(this.getUrl() + `${applicationId}/credit-checks/queue`, data)
      .pipe(map(res => {
        return res;
      }));
  }

  updateWhiteLabel(applicationId: number, whiteLabelId: string) {
    return this.http.put(this.getUrl() + `${applicationId}/white-label/${whiteLabelId}`, {})
      .pipe(map(res => {
        return res;
      }));
  }

  updateApplicationType(applicationId: number, applicationType: string) {
    return this.http.put(this.getUrl() + `${applicationId}/type/${applicationType}`, {})
      .pipe(map(res => {
        return res;
      }));
  }

  sendEvent(applicationId: number, data: any) {
    return this.http.post(this.getUrl() + `${applicationId}/events`, data)
      .pipe(map(res => {
        return res;
      }));
  }

  createBank(applicationId: number, data: any) {
    return this.http.post(this.getUrl() + `${applicationId}/banks`, data)
      .pipe(map(res => {
        return res;
      }));
  }

  addAttachment(applicationId: number, attachmentId: number) {
    return this.http.put(this.getUrl() + `${applicationId}/attachments/${attachmentId}`, {})
      .pipe(map(res => {
        return res;
      }));
  }

  addAttachments(applicationId: number,fileIds: number[]) {
    return this.http.put(this.getUrl() + `${applicationId}/attachments`, { attachments: fileIds})
       .pipe(map(res => {
      return res;
    }))
  }

  deleteAttachment(applicationId: number, attachmentId: number) {
    return this.http.delete(this.getUrl() + `${applicationId}/attachments/${attachmentId}`)
      .pipe(map(res => {
        return res;
      }));
  }

  deleteStipulation(applicationId: number, applicationStipulationId: number) {
    return this.http.delete(this.getUrl() + `${applicationId}/stipulations/${applicationStipulationId}`)
      .pipe(map(res => {
        return res;
      }));
  }

  addStipulation(applicationId: number, data: any) {
    return this.http.put(this.getUrl() + `${applicationId}/stipulations`, data)
      .pipe(map(res => {
        return res;
      }));
  }

  updateStipulation(applicationId: number, applicationStipulationId: number, data: any) {
    return this.http.put(this.getUrl() + `${applicationId}/stipulations/${applicationStipulationId}`, data)
      .pipe(map(res => {
        return res;
      }));
  }

  associateAttachmentToStipulation(applicationId: number, applicationStipulationId: number, attachmentId: number) {
    return this.http.put(this.getUrl() + `${applicationId}/stipulations/${applicationStipulationId}/attachments/${attachmentId}`, {})
      .pipe(map(res => {
        return res;
      }));
  }

  deassociateAttachmentFromStipulation(applicationId: number, applicationStipulationId: number, attachmentId: number) {
    return this.http.delete(this.getUrl() + `${applicationId}/stipulations/${applicationStipulationId}/attachments/${attachmentId}`)
      .pipe(map(res => {
        return res;
      }));
  }

  completeLandlordInterview(applicationId: number) {
    return this.http.put(this.getUrl() + `${applicationId}/interviews/landlord/complete`, {})
      .pipe(map(res => {
        return res;
      }));
  }

  completeMerchantInterview(applicationId: number) {
    return this.http.put(this.getUrl() + `${applicationId}/interviews/merchant/complete`, {})
      .pipe(map(res => {
        return res;
      }));
  }

  resendApprovals(data: any) {
    return this.http.put(this.getUrl() + `resend-approvals`, data)
      .pipe(map(res => {
        return res;
      }));
  }

  listWorkflowStatuses() {
    return this.http.get(this.getUrl() + `workflow/statuses`)
      .pipe(map(res => {
        return res;
      }));
  }

  listWorkflowEvents() {
    return this.http.get(this.getUrl() + `workflow/events`)
      .pipe(map(res => {
        return res;
      }));
  }

  listPositions(applicationId: number) {
    return this.http.get(this.getUrl() + `${applicationId}/positions`)
      .pipe(map(res => {
        return res;
      }));
  }

  listOffers(applicationId: number) {
    return this.http.get(this.getUrl() + `${applicationId}/offers`)
      .pipe(map(res => {
        return res;
      }));
  }

  getAppliedOffer(applicationId: number) {
    return this.http.get(this.getUrl() + `${applicationId}/applied-offer`)
      .pipe(map(res => {
        return res;
      }));
  }

  listESignatureHistory(applicationId: number) {
    return this.http.get(this.getUrl() + `${applicationId}/esignature-history`)
      .pipe(map(res => {
        return res;
      }));
  }

  forceESignatureHistoryRefresh(applicationId: number, eSignatureHistoryId: number) {
    return this.http.put(this.getUrl() + `${applicationId}/esignature-history/${eSignatureHistoryId}/refresh`, {})
      .pipe(map(res => {
        return res;
      }));
  }

  resendESignatureRequest(applicationId: number, eSignatureHistoryId: number, data: any) {
    return this.http.put(this.getUrl() + `${applicationId}/esignature-history/${eSignatureHistoryId}/resend`, data)
      .pipe(map(res => {
        return res;
      }));
  }

  cancelESignatureRequest(applicationId: number, eSignatureHistoryId: number) {
    return this.http.put(this.getUrl() + `${applicationId}/esignature-history/${eSignatureHistoryId}/cancel`, {})
      .pipe(map(res => {
        return res;
      }));
  }

  addPosition(applicationId: number, data: any) {
    return this.http.post(this.getUrl() + `${applicationId}/positions`, data)
      .pipe(map(res => {
      return res;
    }));
  }

  updatePosition(applicationId: number, positionId: number, data: any) {
    return this.http.put(this.getUrl() + `${applicationId}/positions/${positionId}`, data)
      .pipe(map(res => {
      return res;
    }));
  }

  deletePosition(applicationId: number, positionId: number) {
    return this.http.delete(this.getUrl() + `${applicationId}/positions/${positionId}`)
      .pipe(map(res => {
      return res;
    }));
  }

  approveFundings(data: any) {
    return this.http.put(this.getUrl() + `approve`, data)
      .pipe(map(res => {
      return res;
    }));
  }

  getNotes(id: number, category?: string, offset?: number, limit?: number, sortBy?: string, sortDir?: string) {
    const params = super.preparePaginationParams(offset, limit, sortBy, sortDir);

    if (category) { params['category'] = category; }

    return this.http.get(this.getUrl() + `${id}/notes`, { params })
      .pipe(map(res => {
        return res;
      }));
  }

  saveNote(id: number, data: any) {
    return this.http.post(this.getUrl() + `${id}/notes`, data)
      .pipe(map(res => {
        return res;
      }));
  }

  modifyOfferStatus(applicationId: number, offerId: number, action: string) {
    return this.http.put(this.getUrl() + applicationId + '/syndications/' + offerId + '/actions/' + action, {})
      .pipe(map(res => {
        return res;
      }));
  }

  getSyndicatorOffers(applicationId: number) {
    return this.http.get(this.getUrl() + `${applicationId}/syndications`)
      .pipe(map(res => {
        return res;
      }));
  }

  getSyndicatorOffer(applicationId: number, offerId: number) {
    return this.http.get(this.getUrl() + `${applicationId}/syndications/${offerId}`)
      .pipe(map(res => {
        return res;
      }));
  }

  getContract(applicationId: number) {
    return this.http.get(this.getUrl() + `${applicationId}/contract`)
      .pipe(map(res => {
        return res;
      }));
  }

  createOrUpdateContract(applicationId: number, data: any) {
    return this.http.put(this.getUrl() + `${applicationId}/contract`, data)
      .pipe(map(res => {
        return res;
      }));
  }

  updateClientForContract(applicationId: number, data: any) {
    return this.http.put(this.getUrl() + `${applicationId}/contract/client`, data)
      .pipe(map(res => {
        return res;
      }));
  }

  updateAddressForContract(applicationId: number, data: any) {
    return this.http.put(this.getUrl() + `${applicationId}/contract/address`, data)
      .pipe(map(res => {
        return res;
      }));
  }

  updateContactForContract(applicationId: number, data: any) {
    return this.http.put(this.getUrl() + `${applicationId}/contract/contact`, data)
      .pipe(map(res => {
        return res;
      }));
  }

  updateOwnersForContract(applicationId: number, data: any) {
    return this.http.put(this.getUrl() + `${applicationId}/contract/owners`, data)
      .pipe(map(res => {
        return res;
      }));
  }

  createOrUpdateRefinancedFundings(applicationId: number, data: any) {
    return this.http.put(this.getUrl() + `${applicationId}/refinanced-fundings/bulk`, data)
      .pipe(map(res => {
        return res;
      }));
  }

  generateAndSendDisclosure(applicationId: number, data: any) {
    return this.http.post(this.getUrl() + `${applicationId}/disclosure/send`, data)
      .pipe(map(res => {
        return res;
      }));
  }

  generateAndSendContract(applicationId: number, data: any) {
    return this.http.post(this.getUrl() + `${applicationId}/contract/send`, data)
      .pipe(map(res => {
        return res;
      }));
  }

  generateAndDownloadContract(applicationId: number, data: any) {
    return this.http.post(this.getUrl() + `${applicationId}/contract/download`, data)
      .pipe(map(res => {
        return res;
      }));
  }

  generateAndDownloadDisclosure(applicationId: number, data: any) {
    return this.http.post(this.getUrl() + `${applicationId}/disclosure/download`, data)
      .pipe(map(res => {
        return res;
      }));
  }

  disclosureRequired(applicationId: number) {
    return this.http.get(this.getUrl() + `${applicationId}/disclosure-required`)
      .pipe(map(res => {
        return res;
      }),
      catchError((err) => {
        console.error(`CUSTOM ERROR: Checking if disclosure is required ${this.getUrl() + applicationId}/disclosure-required`, err);
        return of({body: false});
      }));
  }

  createAutomaticSyndicationOffer(applicationId: number, data: any) {
    return this.http.post(this.getUrl() + `${applicationId}/syndications/offers/automatic`, data)
      .pipe(map(res => {
        return res;
      }));
  }

  createManualSyndicationOffer(applicationId: number, data: any) {
    return this.http.post(this.getUrl() + `${applicationId}/syndications/offers/manual`, data)
      .pipe(map(res => {
        return res;
      }));
  }

  updateSyndicationOffer(applicationId: number, syndicationOfferId: number, data: any) {
    return this.http.put(this.getUrl() + `${applicationId}/syndications/offers/${syndicationOfferId}`, data)
      .pipe(map(res => {
        return res;
      }));
  }

  updateFinalUnderwriting(applicationId: number, data: any) {
    return this.http.put(this.getUrl() + `${applicationId}/final-underwriting`, data)
      .pipe(map(res => {
        return res;
      }));
  }

  updateUnderwriterNotes(applicationId: number, content: string) {
    return this.http.put(this.getUrl() + `${applicationId}/underwriter-notes`, { content })
      .pipe(map(res => {
        return res;
      }));
  }

  generateDecisionLogicRequest(applicationId: number, data: any) {
    return this.http.post(this.getUrl() + `${applicationId}/decisionlogic/request`, data)
      .pipe(map(res => {
        return res;
      }));
  }

  getDecisionLogicRequest(applicationId: number, decisionLogicId: number, mockData?: boolean) {
    if (mockData) {
      return this.http.get('https://app.onyxiq.com/assets/mock-data/decision-logic-report.json')
      .pipe(map(res => {
        return res;
      }));
    } else {
    return this.http.get(this.getUrl() + `${applicationId}/decisionlogic/${decisionLogicId}`)
      .pipe(map(res => {
        return res;
      }));
    }
  }

  getDecisionLogicReport(applicationId: number) {
    return this.http.get(this.getUrl() + `${applicationId}/decisionlogic/report`)
      .pipe(map(res => {
        return res;
      }));
  }

  getDecisionLogicAggregations(applicationId: number, decisionLogicId: number) {
    return this.http.get(this.getUrl() + `${applicationId}/decisionlogic/${decisionLogicId}/aggregate`)
      .pipe(map(res => {
        return res;
      }));
  }

  sendDecisionLogicRefreshRequest(applicationId: number, decisionLogicId: number) {
    return this.http.put(this.getUrl() + `${applicationId}/decisionlogic/${decisionLogicId}/refresh`, {})
      .pipe(map(res => {
        return res;
      }));
  }

  acceptOffer(id: number, syndicationOfferId: number, acceptedPercentSyndicated: number): Observable<any> {
    return this.http.put(this.getUrl() + `${id}/syndications/offers/${syndicationOfferId}/actions/Accept`, { acceptedPercentSyndicated })
      .pipe(map(res => {
        return res;
      }));
  }

  rejectOffer(id: number, syndicationOfferId: number): Observable<any> {
    return this.http.put(this.getUrl() + `${id}/syndications/offers/${syndicationOfferId}/actions/Reject`, {})
      .pipe(map(res => {
        return res;
      }));
  }

  payOffer(id: number, syndicationOfferId: number, transmissionData): Observable<any> {
    return this.http.put(this.getUrl() + `${id}/syndications/offers/${syndicationOfferId}/actions/Pay`, transmissionData)
      .pipe(map(res => {
        return res;
      }));
  }

  getSettings(id: number): Observable<any> {
    return this.http.get(this.getUrl() + `${id}/settings`)
      .pipe(map(res => {
        return res;
      }));
  }

  updateSettings(id: number, data: any): Observable<any> {
    return this.http.put(this.getUrl() + `${id}/settings`, data)
      .pipe(map(res => {
        return res;
      }));
  }

  addDebt(applicationId: number, data: any) {
    return this.http.post(this.getUrl() + `${applicationId}/debts`, data)
      .pipe(map(res => {
      return res;
    }));
  }

  updateDebt(applicationId: number, debtId: number, data: any) {
    return this.http.put(this.getUrl() + `${applicationId}/debts/${debtId}`, data)
      .pipe(map(res => {
      return res;
    }));
  }

  listDebts(id: number) {
    return this.http.get(this.getUrl() + `${id}/debts`)
      .pipe(map(res => {
        return res;
      }));
  }

  deleteDebt(applicationId: number, debtId: number) {
    return this.http.delete(this.getUrl() + `${applicationId}/debts/${debtId}`)
      .pipe(map(res => {
      return res;
    }));
  }

  addFinancialStatement(applicationId: number, data: any) {
    return this.http.post(this.getUrl() + `${applicationId}/financial-statements`, data)
      .pipe(map(res => {
      return res;
    }));
  }

  updateFinancialStatement(applicationId: number, fsId: number, data: any) {
    return this.http.put(this.getUrl() + `${applicationId}/financial-statements/${fsId}`, data)
      .pipe(map(res => {
      return res;
    }));
  }

  listFinancialStatements(id: number) {
    return this.http.get(this.getUrl() + `${id}/financial-statements`)
      .pipe(map(res => {
        return res;
      }));
  }

  deleteFinancialStatement(applicationId: number, fsId: number) {
    return this.http.delete(this.getUrl() + `${applicationId}/financial-statements/${fsId}`)
      .pipe(map(res => {
      return res;
    }));
  }

  togglePositionActive(applicationId: number, positionId: number, data?: any) {
    return this.http.put(this.getUrl() + `${applicationId}/positions/${positionId}/toggle-active`, data)
      .pipe(map(res => {
        return res;
    }));
  }

  setLandlordInterviewCompleted(applicationId: number) {
    return this.http.put(this.getUrl() + `${applicationId}/interviews/landlord/completed`, {})
      .pipe(map(res => {
        return res;
    }));
  }

  setLandlordMerchantCompleted(applicationId: number) {
    return this.http.put(this.getUrl() + `${applicationId}/interviews/merchant/completed`, {})
      .pipe(map(res => {
        return res;
    }));
  }

  updateUrl(req: string) {
    return environment.servicesUrl + req;
  }

  resubmitApplication(applicationId: number) {
    return this.http.put(this.getUrl() + `${applicationId}/status/Underwriting`, {})
      .pipe(map(res => {
        return res;
    }));
  }

  public resubmitApplicationToStatus(applicationId: number, status: string) {
    return this.http.put(this.getUrl() + `${applicationId}/status/${status}`, {})
      .pipe(map(res => {
        return res;
    }));
  }

  sendNotification(applicationId: number, data: any) {
    return this.http.post(this.getUrl() + `${applicationId}/notifications`, data)
      .pipe(map(res => {
        return res;
      }));
  }

  patchApplicationData(applicationId: number, data: any) {
    return this.http.patch(this.getUrl() + `${applicationId}`, data)
      .pipe(map(res => {
        return res;
    }));
  }

  allInOneSubmit(data: any) {
    return this.http.put(this.getUrl() + 'all-in-one-submit', data)
      .pipe(map(res => {
        return res;
    }));
  }

  allInOneSave(data: any) {
    return this.http.put(this.getUrl() + 'all-in-one-draft', data)
      .pipe(map(res => {
        return res;
    }));
  }

  calculateAmountBasedOnSyndicationCalculationLogic(applicationId: number, offerId: number) {
    return this.http.get(this.getUrl() + `${applicationId}/syndications/offers/${offerId}/payment-amount`)
      .pipe(map(res => {
        return res;
      }));
  }

  getOfferRangeByApplication(applicationId: number): Observable<OfferRange[]>{
   return this.http.get( this.getUrl() + `offer-ranges/application/${applicationId}`)
         .pipe(map((res:any) => {
          return res;
      }));
  }

  createOfferRange(offerRanges: Partial<OfferRange>): Observable<OfferRange>{
    return this.http.post(this.getUrl() + `offer-ranges/`, offerRanges)
          .pipe(map(res => {
            const offerRanges = new OfferRange();
            offerRanges.serialize(res)
            return offerRanges;
       }));
   }

   deleteOfferRange(offerRangesId: number){
    return this.http.delete(this.getUrl() + `offer-ranges/${offerRangesId}` )
          .pipe(map(res => {
         return res;
       }));
   }

  sendOfferRangeToISO(offerRangeId: number, notificationRecepients?){
    return this.http.post(this.getUrl() +`offer-range/${offerRangeId}/iso-invitation`, notificationRecepients)
        .pipe(map(res => {
       return res;
     }));
  }

  public listPreUnderwritingQueue(offset?: number, limit?: number, sortBy?: string, sortDir?: string): Observable<ApiResponse<Application>> {
    const params = super.preparePaginationParams(offset, limit, sortBy, sortDir);
    return this.http.get<ApiResponse<Application>>(this.getUrl() + `pre-underwriting-queue`, { params: params });
  }

  public listPreUnderwriting(offset?: number, limit?: number, sortBy?: string, sortDir?: string): Observable<ApiResponse<Application>> {
    const params = super.preparePaginationParams(offset, limit, sortBy, sortDir);
    return this.http.get<ApiResponse<Application>>(this.getUrl() + `pre-underwriting`, { params: params });
  }

  updateOfferRange(offerRangeId: number, notificationRecepients? ){
      return this.http.put(this.getUrl() +`offer-ranges/broker-portal/${offerRangeId}/`, notificationRecepients)
          .pipe(map(res => {
         return res;
       }));
      }

  createBulkSyndications(fundingId: number, data ){
      return this.http.post(`offers/${fundingId}/syndication-offers/bulk`, data)
          .pipe(map(res => {
         return res;
       }));
  }

  public getApplicationLastStatusPriorToDecline(applicationId):Observable<string> {
    return this.http.put<string>(this.getUrl() + `${applicationId}/resubmit-from-decline`, {})
  }

  public sendToPreUnderwritingAfterRevision(applicationId: number, data: any):Observable<string> {
    return this.http.put<string>(this.getUrl() + `${applicationId}/resubmit-from-revision`, data);
  }

  public getApplicationStatus(applicationId):Observable<string> {
    return this.http.get<string>(this.getUrl() + `${applicationId}/status`, {})
  }

  public getWorkflowEventsTemplateNames(): Observable<any> {
    return this.http.get<any>(this.getUrl()+ `workflow/events/template-names`);
  }

  /**
   * This method updates the stipulation status and returns the updated stipulations.
   * The current stipulations data is passed as a parameter to get the updated stipulation status in the component.
   */
  getUpdatedStipulationsWithStatus(
      applicationId: number,
      stipulationId: number,
      status: StipulationStatus,
      notes: string,
      data: Stipulation[],
    ): Observable<Stipulation[]> {
      return this.updateStipulationStatus(applicationId, stipulationId, status, notes)
        .pipe(
          tap(() => this.reloadApplication(applicationId)),
          map(() => {
            return data.map((stipulation) => {
              if (stipulation.id === stipulationId) {
                return { ...stipulation, notes, status };
              }
              return stipulation;
            });
          })
        );
  }

  public refreshCreditCheck(appId, creditCheckId) {
    return this.http.post(this.getUrl() + `${appId}/credit-checks/${creditCheckId}/refresh`, {})
      .pipe(map(res => {
        return res;
      }));
  }
}
