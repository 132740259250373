<table mat-table [dataSource]="datasource" matSort multiTemplateDataRows matSortDirection="desc" class="clickable">
  <ng-container matColumnDef="view">
    <th mat-header-cell *matHeaderCellDef>&nbsp;</th>
    <td mat-cell *matCellDef="let row">
      <div class="expand-cell" (click)="toggleRepaymentsRow(row, $event)">
        <i class="material-icons" *ngIf="row !== expandedElement">arrow_right</i>
        <i class="material-icons" *ngIf="row === expandedElement">arrow_drop_down</i>
      </div>
    </td>
    <td mat-footer-cell *matFooterCellDef></td>
  </ng-container>

  <ng-container matColumnDef="expandedDetail">
    <td mat-cell *matCellDef="let row" [attr.colspan]="columns.length">
      <div class="row-details" [@detailExpand]="row == expandedElement ? 'expanded' : 'collapsed'">
        <table class="inner-table" *ngIf="repayments.length else noRepayments">
          <thead>
          <tr>
            <th>Amount</th>
            <th>Hit Date</th>
            <th>Settlement Date</th>
            <th>Created Date</th>
          </tr>
          </thead>
          <tbody>
            <tr *ngFor="let repayment of repayments">
              <td>{{ repayment.amount | currency }}</td>
              <td>{{ (repayment.hitDate | date) || 'n/a' }}</td>
              <td>{{ (repayment.settlementDate | date) || 'n/a' }}</td>
              <td>{{ repayment.createdDate | date }}</td>
            </tr>
          </tbody>
        </table>
        <ng-template #noRepayments>
          <div class="py-2 px-3">
            <p *ngIf="!loadingRepayments">No records to display</p>
            <p class="loading-records" *ngIf="loadingRepayments">Loading</p>
          </div>
        </ng-template>
      </div>
    </td>
  </ng-container>

  <ng-container matColumnDef="syndicator">
    <th mat-header-cell *matHeaderCellDef mat-sort-header> Syndicator</th>
    <td mat-cell *matCellDef="let row"> {{ row.syndicatorName }} </td>
  </ng-container>
  <ng-container matColumnDef="syndicatedPercent">
    <th mat-header-cell *matHeaderCellDef mat-sort-header> Syndicated Percent</th>
    <td mat-cell *matCellDef="let row"> {{ row.syndicatedPercent }}% </td>
  </ng-container>
  <ng-container matColumnDef="principalSyndicated">
    <th mat-header-cell *matHeaderCellDef> Principal Syndicated</th>
    <td mat-cell *matCellDef="let row"> {{ (row.fundedAmountSyndicated | currency) || 'n/a' }} </td>
  </ng-container>
  <ng-container matColumnDef="status">
    <th mat-header-cell *matHeaderCellDef mat-sort-header> Status</th>
    <td mat-cell *matCellDef="let row"> {{ row.status | status }} </td>
  </ng-container>
  <ng-container matColumnDef="action">
    <th mat-header-cell *matHeaderCellDef mat-sort-header>&nbsp;</th>
    <td mat-cell *matCellDef="let row">

    </td>
  </ng-container>
  <tr mat-header-row *matHeaderRowDef="columns"></tr>
  <tr mat-row *matRowDef="let row; columns: columns;"></tr>
  <tr mat-row *matRowDef="let row; columns: ['expandedDetail']" class="detail-row"></tr>
</table>
<oiq-loading-message [isDataTable]="true" [showNoRecordsMessage]="datasource?.data?.length === 0"></oiq-loading-message>
<mat-paginator [length]="total" [pageSize]="25" [pageSizeOptions]="[25, 50, 100]"></mat-paginator>
