export const applicationTypeMapper = {
  New_Deal: 'New',
  Renewal: 'Renew',
  Resubmission: 'Resubmit',
  Renewal_Resubmission: 'Renew / Resubmit',
};

export function mapDefaultApplicationType(type: string, isNative: boolean): string {
  if (isNative && applicationTypeMapper[type]) {
    return applicationTypeMapper[type];
  }
  return type;
}
